import React, { useEffect, useState, useCallback } from 'react';
import { BasicTableRow, getBasicTableData, Pagination, Tag } from 'api/table.api';
import { BaseTable } from '@app/components/common/BaseTable/BaseTable';
import { ColumnsType } from 'antd/es/table';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { useTranslation } from 'react-i18next';
import { defineColorByPriority } from '@app/utils/utils';
import { notificationController } from 'controllers/notificationController';
import { Status } from '@app/components/profile/profileCard/profileFormNav/nav/payments/paymentHistory/Status/Status';
import { useMounted } from '@app/hooks/useMounted';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { BaseSpace } from '@app/components/common/BaseSpace/BaseSpace';
import { EditOutlined, EyeOutlined, LockOutlined } from '@ant-design/icons';
import { HeaderSearch } from '@app/components/header/components/HeaderSearch/HeaderSearch';
import { Button, DatePicker, Form, Input, Modal, Select, Table, Upload } from 'antd';
import { getUsers, User } from '@app/api/user.api';
import { InputSearch } from '@app/components/header/components/HeaderSearch/HeaderSearch.styles';

export const AdminTable: React.FC = () => {
  const [tableData, setTableData] = useState<{ data: User[]; loading: boolean }>({
    data: [],
    loading: false,
  });
  const [isModalVisible, setIsModalVisible] = useState(false);
  const { t } = useTranslation();
  const { isMounted } = useMounted();
  const [form] = Form.useForm();
  const [filterStatus, setFilterStatus] = useState<number | null>(null);
  const [searchTerm, setSearchTerm] = useState('');

  const fetch = useCallback(
    (statusFilter: number | null = null, search = '') => {
      setTableData((tableData) => ({ ...tableData, loading: true }));
      getUsers({ current: 1, pageSize: 10 }).then((res) => {
        if (isMounted.current) {
          let filteredData = res.data;

          if (statusFilter !== null) {
            filteredData = filteredData.filter((user) => user.IsLocked === statusFilter);
          }

          setTableData({ data: filteredData, loading: false });
        }
      });
    },
    [isMounted],
  );

  useEffect(() => {
    fetch(filterStatus);
  }, [fetch, filterStatus]);

  const handleTableChange = () => {
    fetch(filterStatus);
  };

  const handleFilterStatus = (status: number | null) => {
    setFilterStatus(status);
    fetch(status);
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
  };

  const handleDeleteRow = (rowId: number) => {
    // setTableData({
    //   ...tableData,
    //   data: tableData.data.filter((item) => item.key !== rowId),
    //   pagination: {
    //     ...tableData.pagination,
    //     total: tableData.pagination.total ? tableData.pagination.total - 1 : tableData.pagination.total,
    //   },
    // });
  };

  const columns: ColumnsType<BasicTableRow> = [
    {
      title: t('common.ID'),
      dataIndex: 'UserId',
      width: '10%',
      align: 'right',
    },
    {
      title: t('common.name'),
      dataIndex: 'Username',
      width: '15%',
    },
    {
      title: t('common.sdt'),
      dataIndex: 'PhoneNumber',
      width: '10%',
    },
    {
      title: t('common.ngaycapnhat'),
      dataIndex: 'UpdatedAt',
      width: '10%',
      align: 'center',
      render: (record) => new Date(record).toLocaleDateString('vi-VN'),
    },
    {
      title: t('common.nguoicapnhat'),
      dataIndex: 'UpdatedBy',
      width: '15%',
    },
    {
      title: t('common.tinhtrang'),
      dataIndex: 'IsLocked',
      width: '20%',
      align: 'center',
      render: (value) => (
        <span style={{ backgroundColor: value > 0 ? '#2fb350' : '#ffcc00', padding: '5px 10px', borderRadius: '10px' }}>
          {value > 0 ? 'Vô hiệu hoá' : 'Đang hoạt động'}
        </span>
      ),
    },
    // {
    //   title: t('tables.actions'),
    //   dataIndex: 'actions',
    //   width: '15%',
    //   render: (text: string, record: { name: string; key: number }) => {
    //     return (
    //       <BaseSpace>
    //         <BaseButton type="ghost" onClick={showModal}>
    //           {t('tables.chitiet')}
    //         </BaseButton>
    //         <BaseButton type="default" danger onClick={() => handleDeleteRow(record.key)}>
    //           {t('tables.khoa')}
    //         </BaseButton>
    //       </BaseSpace>
    //     );
    //   },
    // },
  ];

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleSubmit = (values: unknown) => {
    console.log('Received values:', values);
    setIsModalVisible(false);
  };

  const modalChitiet = (
    <Modal
      title="Chi Tiết Người Dùng"
      visible={isModalVisible}
      onCancel={handleCancel}
      footer={null}
      style={{ top: 10 }}
    >
      <Form form={form} layout="inline" onFinish={handleSubmit}>
        <Form.Item
          style={{ width: '30%', marginBottom: '10px' }}
          label="ID"
          name="id"
          rules={[{ required: true, message: 'Vui lòng không bỏ trống!' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          style={{ width: '60%', marginBottom: '10px' }}
          label="Tên Người dùng"
          name="username"
          rules={[{ required: true, message: 'Vui lòng không bỏ trống!' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          style={{ width: '30%', marginBottom: '10px' }}
          label="SĐT"
          name="phone"
          rules={[{ required: true, message: 'Vui lòng không bỏ trống!' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          style={{ width: '60%', marginBottom: '10px' }}
          label="Email"
          name="email"
          rules={[{ required: true, message: 'Vui lòng không bỏ trống!' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          style={{ width: '25%', marginBottom: '10px' }}
          label="Giới tính"
          name="gender"
          rules={[{ required: true, message: 'Vui lòng chọn giới tính!' }]}
        >
          <Select>
            <Select.Option value="Nam">Nam</Select.Option>
            <Select.Option value="Nữ">Nữ</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item
          style={{ width: '65%', marginBottom: '10px' }}
          label="Mật khẩu"
          name="password"
          rules={[{ required: true, message: 'Vui lòng không bỏ trống!' }]}
        >
          <Input.Password />
        </Form.Item>
        <Form.Item
          style={{ width: '25%', marginBottom: '10px' }}
          label="Phân loại"
          name="role"
          rules={[{ required: true, message: 'Vui lòng chọn phân loại!' }]}
        >
          <Select>
            <Select.Option value="Hs/Sv">Hs/Sv</Select.Option>
            <Select.Option value="Giáo viên">Giáo viên</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item
          style={{ width: '55%', marginBottom: '40px' }}
          label="Ngày đăng ký"
          name="registrationDate"
          rules={[{ required: true, message: 'Vui lòng chọn ngày đăng ký!' }]}
        >
          <DatePicker />
        </Form.Item>
        <Form.Item style={{ width: '100%', borderTop: '1px solid #d9d9d9' }}>
          <Button
            type="primary"
            htmlType="submit"
            size="middle"
            style={{ width: '30%', marginLeft: '70%', marginTop: '10px' }}
          >
            <EditOutlined /> Cập nhật
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );

  const filteredData = tableData.data.filter(
    (item) =>
      String(item.Username).toLowerCase().includes(searchTerm.toLowerCase()) ||
      String(item.PhoneNumber).toLowerCase().includes(searchTerm.toLowerCase()),
  );

  return (
    <div style={{ background: 'white', borderRadius: '10px', margin: '20px', padding: '0 10px' }}>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          height: '80px',
        }}
      >
        <BaseButton style={{ marginLeft: '10px' }} onClick={() => handleFilterStatus(null)}>
          {t('Tất cả')}
        </BaseButton>
        <BaseButton style={{ marginLeft: '10px' }} onClick={() => handleFilterStatus(0)}>
          {t('tables.hoatdong')}
        </BaseButton>
        <BaseButton style={{ marginLeft: '10px' }} onClick={() => handleFilterStatus(1)}>
          {t('tables.dakhoa')}
        </BaseButton>
        <div style={{ marginLeft: 'auto' }}>
          <InputSearch
            style={{ width: '350px', marginRight: '10px' }}
            placeholder="Tìm kiếm theo Họ tên, Số điện thoại"
            onChange={handleSearch}
          />
        </div>
      </div>
      <BaseTable
        columns={columns}
        dataSource={filteredData}
        pagination={{ pageSize: 10 }}
        loading={tableData.loading}
        onChange={handleTableChange}
        scroll={{ x: 800 }}
        bordered
      />
      {modalChitiet}
    </div>
  );
};
