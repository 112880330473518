import { APIResponse, httpApi } from './http.api';
import { Pagination } from './table.api';

export interface ViTri {
  LocationId: string;
  LocationName: string;
  CategoryName: string;
  CategoryId: string;
  CategoryCode: string;
  BookCount: number;
  UpdatedAt: string;
  UpdatedBy: string;
  StatusName: string;
  IsLooked: number;
}

export interface ViTriResponse {
  data: ViTri[];
  pagination: Pagination;
}

export const getViTri = async (pagination: Pagination) => {
  const getvitri: ViTri[] = await httpApi.get<APIResponse>('/location').then((res) => res.data.data);
  return {
    data: getvitri,
    pagination: { ...pagination, total: getvitri.length },
  };
};

export const getViTriById = async (LocationId: string) => {
  const vitri: ViTri = await httpApi
    .get<APIResponse>(`/location/getById`, {
      params: {
        LocationId: LocationId,
      },
    })
    .then((res) => res.data.data);
  console.log(vitri);
  return vitri;
};

export const postViTri = async (data: any): Promise<ViTri> => {
  try {
    const response = await httpApi.post<APIResponse>(`/location/store`, data);
    const postViTri: ViTri = response.data.data;
    console.log(postViTri);
    return postViTri;
  } catch (error) {
    console.error('Error posting location', error);
    throw error;
  }
};

export const putLock = async (LocationId: string) => {
  const putLock: ViTri = await httpApi
    .put<APIResponse>(`/location/lock`, {
      LocationId: LocationId,
    })
    .then((res) => res.data.data);
  console.log(putLock);
  return putLock;
};

export const putUnlock = async (LocationId: string) => {
  const putUnlock: ViTri = await httpApi
    .put<APIResponse>(`/location/unlock`, {
      LocationId: LocationId,
    })
    .then((res) => res.data.data);
  console.log(putUnlock);
  return putUnlock;
};

export const putUpdate = async (loactionData: { LocationId: number; LocationName: string; CategoryId: number }) => {
  try {
    const response = await httpApi.put<APIResponse>(`/location/update`, {
      ...loactionData,
    });
    return response.data.data;
  } catch (error) {
    console.error('Error updating location:', error);
    throw new Error('Could not update location');
  }
};
