import { APIResponse, httpApi } from '@app/api/http.api';
// import './mocks/auth.api.mock';
import { UserModel } from '@app/domain/UserModel';

export interface AuthData {
  email: string;
  password: string;
}

export interface SignUpRequest {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
}

export interface ResetPasswordRequest {
  email: string;
}

export interface SecurityCodePayload {
  code: string;
}

export interface NewPasswordData {
  newPassword: string;
}

export interface LoginRequest {
  Username: string;
  Password: string;
}

export interface LoginResponse {
  AccessToken: string;
  RefreshToken: string;
  TokenType: string;
  User: UserModel;
}

export interface RefreshResponse {
  AccessToken: string;
}

export interface LogoutRequest {
  RefreshToken: string;
}

export const login = (loginPayload: LoginRequest): Promise<LoginResponse> =>
  httpApi.post<APIResponse>('auth/login', { ...loginPayload }).then(({ data }) => {
    console.log(data);
    return data.data;
  });

export const refresh = (refreshToken: string): Promise<RefreshResponse> =>
  httpApi.post<APIResponse>('auth/refresh', { RefreshToken: refreshToken }).then(({ data }) => {
    console.log(data);
    return data.data;
  });

export const logout = (logoutPayload: LogoutRequest): Promise<LoginResponse> =>
  httpApi.post<APIResponse>('auth/logout', { ...logoutPayload }).then(({ data }) => {
    console.log(data);
    return data.data;
  });

// export const profile = (): Promise<ProfileResponse> =>
//   httpApi.post<LoginResponse>('auth/user-profile').then(({ data }) => data);

export const signUp = (signUpData: SignUpRequest): Promise<undefined> =>
  httpApi.post<undefined>('auth/register', { ...signUpData }).then(({ data }) => data);

export const resetPassword = (resetPasswordPayload: ResetPasswordRequest): Promise<undefined> =>
  httpApi.post<undefined>('auth/forgotPassword', { ...resetPasswordPayload }).then(({ data }) => data);

export const verifySecurityCode = (securityCodePayload: SecurityCodePayload): Promise<undefined> =>
  httpApi.post<undefined>('auth/verifySecurityCode', { ...securityCodePayload }).then(({ data }) => data);

export const setNewPassword = (newPasswordData: NewPasswordData): Promise<undefined> =>
  httpApi.post<undefined>('auth/setNewPassword', { ...newPasswordData }).then(({ data }) => data);
