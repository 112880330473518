import { APIResponse, httpApi } from './http.api';
import { Pagination } from './table.api';

export interface Category {
  CategoryId: number;
  CategoryCode: string;
  CategoryName: string;
  BookCount: number;
  Locations: {
    LocationId: string;
    LocationName: string;
  }[];
  UpdatedAt: string;
  UpdatedBy: string;
}

export interface CategoryResponse {
  data: Category[];
  pagination: Pagination;
}

export const getCategories = async (pagination: Pagination) => {
  const categories: Category[] = await httpApi.get<APIResponse>('/category').then((res) => res.data.data);
  return {
    data: categories,
    pagination: { ...pagination, total: categories.length },
  };
};
