/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useState } from 'react';
import { useResponsive } from '@app/hooks/useResponsive';
import { UploadOutlined } from '@ant-design/icons';
import { Button, Modal, Form, Input, Row, Col, Upload, Select, message } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { storeBookBiblio } from '@app/api/book.api';
import { UploadFile } from 'antd/es/upload/interface';
import { useMounted } from '@app/hooks/useMounted';
import { Category, getCategories } from '@app/api/category.api';
import { getlocations, Location } from '@app/api/location.api';

type Props = {
  isModalVisible: boolean;
  toggleShowThemSach: () => void;
};

function ThemSach(props: Props) {
  const [isClicked, setIsClicked] = useState(false);
  const [Categorys, setCategorys] = useState<{ data: Category[]; loading: boolean }>({
    data: [],
    loading: false,
  });
  const [Locations, setLocations] = useState<{ data: Location[]; loading: boolean }>({
    data: [],
    loading: false,
  });
  const [form] = Form.useForm();
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [pdfList, setPdfList] = useState<UploadFile[]>([]);
  const { isMounted } = useMounted();
  const [selectedCategory, setSelectedCategory] = useState<number>(25);

  const fetch = useCallback(() => {
    setCategorys((tableData) => ({ ...tableData, loading: true }));
    getCategories({ current: 1, pageSize: 10 }).then((res) => {
      if (isMounted.current) {
        setCategorys({ data: res.data, loading: false });
      }
    });

    setLocations((tableData) => ({ ...tableData, loading: true }));
    getlocations({ current: 1, pageSize: 10 }).then((res) => {
      if (isMounted.current) {
        setLocations({ data: res.data, loading: false });
      }
    });
  }, [isMounted]);

  useEffect(() => {
    fetch();
  }, [fetch]);

  const handleFileChange = ({ fileList }: { fileList: UploadFile[] }) => {
    setFileList(fileList);
  };

  const handlePdfChange = ({ fileList }: { fileList: UploadFile[] }) => {
    setPdfList(fileList);
  };

  const onFinish = async (values: any) => {
    try {
      setIsClicked(true);

      const formData = new FormData();
      formData.append('ISBN', values.ISBN ? values.ISBN : '');
      formData.append('BookName', values.BookName);
      formData.append('AuthorName', values.Authors);
      formData.append('PublisherName', values.Publisher);
      formData.append('PublishYear', values.PublishYear);
      formData.append('CategoryId', values.CategoryId);
      formData.append('LocationId', values.LocationId);
      formData.append('Notes', values.Notes);

      if (fileList.length > 0 && fileList[0].originFileObj) {
        const thumbnailFile = fileList[0].originFileObj as File;
        formData.append('Thumbnail', thumbnailFile);
      }
      if (pdfList.length > 0) {
        pdfList.forEach((file) => {
          formData.append('Files[]', file.originFileObj as File);
        });
      }
      // console.log('Form data:', formData, values);

      const response = await storeBookBiblio(formData);
      // console.log('Response:', response);
      // return;

      form.resetFields();
      setFileList([]);
      props.toggleShowThemSach();
      message.success('Sách đã được thêm thành công.');
      setIsClicked(false);
      window.location.reload();
    } catch (error: any) {
      setIsClicked(false);
      message.error('Đã có lỗi xảy ra.');
    }
  };

  const handleCategoryChange = (value: number) => {
    setSelectedCategory(value);
  };

  return (
    <Modal
      title="Thêm Sách"
      open={props.isModalVisible}
      onCancel={() => {
        form.resetFields();
        props.toggleShowThemSach();
      }}
      footer={
        <div style={{ textAlign: 'center' }}>
          <Button
            type="primary"
            htmlType="submit"
            onClick={() => {
              form
                .validateFields()
                .then((values) => {
                  onFinish(values);
                })
                .catch((info) => {
                  console.log('Validate Failed:', info);
                });
            }}
            size="middle"
            disabled={isClicked}
            loading={isClicked}
          >
            Xác Nhận
          </Button>
        </div>
      }
      style={{ top: 20 }}
      width={800}
    >
      <Form layout="vertical" form={form} onFinish={onFinish}>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              label="Tên sách"
              name="BookName"
              rules={[{ required: true, message: 'Vui lòng không bỏ trống!' }]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Danh mục"
              name="CategoryId"
              rules={[{ required: true, message: 'Vui lòng chọn danh mục!' }]}
              initialValue={selectedCategory}
            >
              <Select value={selectedCategory} onChange={handleCategoryChange}>
                <Select.Option value="Default">Danh mục</Select.Option>
                {Categorys.data.map((category) => (
                  <Select.Option key={category.CategoryCode} value={category.CategoryId}>
                    {category.CategoryName}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="ISBN" name="ISBN">
              <Input style={{ fontWeight: 'normal' }} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Tác giả" name="Authors" initialValue={'KHÔNG XÁC ĐỊNH'}>
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Nhà xuất bản"
              name="Publisher"
              rules={[{ required: true, message: 'Vui lòng không bỏ trống!' }]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Năm xuất bản" name="PublishYear" initialValue={2024}>
              <Input type="number" />
            </Form.Item>
          </Col>
          <Col span={18}>
            <Form.Item label="Ghi chú" name="Notes" initialValue={null}>
              <TextArea autoSize={{ minRows: 1 }} />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label="Ảnh bìa sách" name="Thumbnail">
              <Upload
                listType="picture"
                maxCount={1}
                fileList={fileList}
                beforeUpload={() => false}
                onChange={handleFileChange}
              >
                <Button icon={<UploadOutlined />}>Chọn ảnh</Button>
              </Upload>
            </Form.Item>
            <Form.Item label="Tài liệu đính kèm" name="Files">
              <Upload multiple fileList={pdfList} beforeUpload={() => false} onChange={handlePdfChange}>
                <Button icon={<UploadOutlined />}>Chọn ảnh</Button>
              </Upload>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}

export default ThemSach;
