import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { UserModel } from '@app/domain/UserModel';
import * as S from './ProfileInfo.styles';
import { BaseAvatar } from '@app/components/common/BaseAvatar/BaseAvatar';

interface ProfileInfoProps {
  profileData: UserModel | null;
}

export const ProfileInfo: React.FC<ProfileInfoProps> = ({ profileData }) => {
  const [fullness] = useState(90);

  const { t } = useTranslation();

  return profileData ? (
    <S.Wrapper>
      <S.ImgWrapper>
        <BaseAvatar
          shape="circle"
          src={
            profileData?.UserImage ||
            'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRMa0vsQlVnTOg7LAJkKLINzs5TlUG3zkaNZA&s'
          }
          alt="Profile"
        />
      </S.ImgWrapper>
      <S.Title>{`${profileData?.Realname}`}</S.Title>
      <S.Subtitle>{profileData?.UserType}</S.Subtitle>
      <S.FullnessWrapper>
        <S.FullnessLine width={fullness}>{fullness}%</S.FullnessLine>
      </S.FullnessWrapper>
      <S.Text>{t('profile.fullness')}</S.Text>
    </S.Wrapper>
  ) : null;
};
