import React, { useState } from 'react';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import { useResponsive } from '@app/hooks/useResponsive';
import * as S from '../DashboardPages/DashboardPage.styles';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { PlusOutlined } from '@ant-design/icons';
import { ThanhVienTable } from '@app/components/tables/BasicTable/ThanhVienTable';
import { Button, PageHeader, Modal, Form, Input, Row, Col, message, Select } from 'antd';
import './Pages.css';
import { postMember } from '@app/api/thanhvien.api';
import TextArea from 'antd/lib/input/TextArea';
import moment from 'moment';

const ThanhVien: React.FC = () => {
  const { isDesktop } = useResponsive();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [form] = Form.useForm();
  const [gender, setGender] = useState(0);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    form.resetFields();
  };

  const generateUniqueBarcode = (): string => {
    const numberPart = Math.floor(Math.random() * 100000)
      .toString()
      .padStart(10, '0');

    return `${numberPart}`;
  };

  const validateEmail = (email: string) => {
    const gmailRegex = /^[a-zA-Z0-9._%+-]+@gmail\.com$/;
    return gmailRegex.test(email);
  };

  const onFinish = async (values: any) => {
    const uniqueBarcode = generateUniqueBarcode();

    const transformedValues = {
      MemberName: values.MemberName,
      MemberId: values.MemberId,
      Barcodes: uniqueBarcode,
      Gender: Number(values.Gender),
      BirthDate: values.BirthDate ? moment(values.BirthDate, 'DD/MM/YYYY').format('YYYY-MM-DD') : null,
      MemberAddress: values.MemberAddress,
      MemberEmail: values.MemberEmail,
      MemberPhone: values.MemberPhone,
      MemberType: values.MemberType,
      Class: values.Class,
    };

    console.log('Received values from form: ', transformedValues);
    const response = await postMember(transformedValues);
    setIsModalVisible(false);
    form.resetFields();
    console.log('API response:', response);
    message.success('Thành viên đã được thêm thành công.');
    window.location.reload();
  };

  const handleDateChange = (value: string) => {
    if (value.length > 10) return;
    const numbers = value.replace(/[^0-9]/g, '');
    let formattedValue = '';
    if (numbers.length >= 2) {
      formattedValue += numbers.slice(0, 2);
      if (numbers.length > 2) {
        formattedValue += '/' + numbers.slice(2, 4);
        if (numbers.length > 4) {
          formattedValue += '/' + numbers.slice(4, 8);
        }
      }
    } else {
      formattedValue = numbers;
    }
    form.setFieldsValue({ BirthDate: formattedValue });
  };

  const modalThem = (
    <Modal
      title="Thêm Thành Viên"
      open={isModalVisible}
      onCancel={handleCancel}
      footer={
        <div style={{ textAlign: 'center' }}>
          <Button
            type="primary"
            htmlType="submit"
            onClick={() => {
              form
                .validateFields()
                .then((values) => {
                  onFinish(values);
                })
                .catch((info) => {
                  console.log('Validate Failed:', info);
                });
            }}
            size="middle"
          >
            Xác Nhận
          </Button>
        </div>
      }
      style={{ top: 20 }}
      width={800}
    >
      <Form layout="vertical" onFinish={onFinish} form={form}>
        <Row gutter={16}>
          <Col span={8}>
            <Form.Item
              label="Họ và Tên"
              name="MemberName"
              rules={[{ required: true, message: 'Vui lòng không bỏ trống!' }]}
            >
              <Input style={{ fontWeight: 'normal' }} />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="ID Thành viên"
              name="MemberId"
              rules={[{ required: true, message: 'Vui lòng không bỏ trống!' }]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="Lớp" name="Class" rules={[{ required: true, message: 'Vui lòng không bỏ trống!' }]}>
              <Input />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              initialValue={gender}
              label="Gới tính"
              name="Gender"
              rules={[{ required: true, message: 'Vui lòng không bỏ trống!' }]}
            >
              <Select value={gender} onChange={(e) => setGender(e)}>
                <Select.Option value={0}>Nam</Select.Option>
                <Select.Option value={1}>Nữ</Select.Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="Sinh nhật"
              name="BirthDate"
              rules={[{ required: true, message: 'Vui lòng không bỏ trống!' }]}
            >
              <Input onChange={(e) => handleDateChange(e.target.value)} maxLength={10} placeholder="DD/MM/YYYY" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="Địa chỉ"
              name="MemberAddress"
              rules={[{ required: true, message: 'Vui lòng không bỏ trống!' }]}
              initialValue={'TP.HCM'}
            >
              <TextArea autoSize={{ minRows: 1, maxRows: 8 }} />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="Số điện thoại"
              name="MemberPhone"
              rules={[
                { required: true, message: 'Vui lòng không bỏ trống!' },
                { len: 10, message: 'Số điện thoại phải có 10 số!' },
              ]}
            >
              <Input maxLength={10} />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="Email"
              name="MemberEmail"
              rules={[
                { required: true, message: 'Vui lòng không bỏ trống!' },
                {
                  validator: (_, value) =>
                    validateEmail(value) ? Promise.resolve() : Promise.reject('Email phải có "@"'),
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="Phân loại"
              name="MemberType"
              rules={[{ required: true, message: 'Vui lòng chọn phân loại!' }]}
            >
              <Select value={'Học sinh / Sinh viên'}>
                <Select.Option value="Học sinh / Sinh viên">Học sinh / Sinh viên</Select.Option>
                <Select.Option value="Giảng viên">Giảng viên</Select.Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );

  const desktopLayout = (
    <>
      <BaseRow>
        <S.LeftSideCol>
          <BaseRow gutter={[60, 60]}>
            <BaseCol span={24}>
              <div className="container">
                <PageHeader
                  className="page-header"
                  title="Thành viên"
                  footer="Thông tin thành viên của thư viện"
                  extra={[
                    <Button key="1" type="primary" icon={<PlusOutlined />} onClick={showModal}>
                      Thêm Thành Viên
                    </Button>,
                  ]}
                />
                <ThanhVienTable />
              </div>
            </BaseCol>
          </BaseRow>
        </S.LeftSideCol>
      </BaseRow>
    </>
  );

  const mobileAndTabletLayout = (
    <BaseRow gutter={[20, 24]}>
      <BaseCol span={24}>
        <div className="container">
          <PageHeader
            className="page-header"
            title="Thành viên"
            footer="Thông tin thành viên của thư viện"
            extra={[
              <Button key="1" type="primary" icon={<PlusOutlined />} onClick={showModal}>
                Thêm Thành Viên
              </Button>,
            ]}
          />
          <ThanhVienTable />
        </div>
      </BaseCol>
    </BaseRow>
  );

  return (
    <>
      <PageTitle>ThanhVien</PageTitle>
      {isDesktop ? desktopLayout : mobileAndTabletLayout}
      {modalThem}
    </>
  );
};

export default ThanhVien;
