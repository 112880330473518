import { APIResponse, httpApi } from './http.api';
import { Pagination } from './table.api';

export interface Member {
  MemberId: string;
  MemberName: string;
  PhoneNumber: string;
  Class: string;
  Email: string;
  BirthDate: string;
  MemberTypeId: number;
  MemberTypeName: string;
  MemberAddress: string;
  CreatedAt: string;
  BorrowedBooks: number;
  BorrowingBooks: number;
  IsLocked: number;

  LoanDetails: {
    BookId: number;
    LoanDate: string;
    RetunDate: string | null;
  }[];

  Gender: number;
  MemberEmail: string;
  MemberPhone: string;
}

export interface MemberResponse {
  data: Member[];
  pagination: Pagination;
}

export const getMember = async (pagination: Pagination) => {
  const members: Member[] = await httpApi.get<APIResponse>('/member').then((res) => res.data.data);
  return {
    data: members,
    pagination: { ...pagination, total: members.length },
  };
};

export const getMemberById = async (MemberId: string) => {
  const Memberid: Member = await httpApi
    .get<APIResponse>(`/member/getById`, {
      params: {
        MemberId: MemberId,
      },
    })
    .then((res) => res.data.data);
  console.log(Memberid);
  return Memberid;
};

export const postMember = async (data: any): Promise<Member> => {
  try {
    const response = await httpApi.post<APIResponse>(`/member/store`, data);
    const postMember: Member = response.data.data;
    console.log(postMember);
    return postMember;
  } catch (error) {
    console.error('Error posting member:', error);
    throw error;
  }
};

export const putLock = async (MemberId: string) => {
  const putLock: Member = await httpApi
    .put<APIResponse>(`/member/lock`, {
      MemberId: MemberId,
    })
    .then((res) => res.data.data);
  console.log(putLock);
  return putLock;
};

export const putUnlock = async (MemberId: string) => {
  const putUnlock: Member = await httpApi
    .put<APIResponse>(`/member/unlock`, {
      MemberId: MemberId,
    })
    .then((res) => res.data.data);
  console.log(putUnlock);
  return putUnlock;
};

export const putUpdate = async (memberData: {
  OldMemberId: string;
  NewMemberId: string;
  MemberName: string;
  Class: string;
  Gender: number;
  BirthDate: string;
  MemberAddress: string;
  MemberTypeId: number;
  MemberPhone: string;
  MemberEmail: string;
}) => {
  try {
    const response = await httpApi.put<APIResponse>(`/member/update`, {
      ...memberData,
    });
    return response.data.data;
  } catch (error) {
    console.error('Error updating member:', error);
    throw new Error('Could not update member');
  }
};
