/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState, useCallback } from 'react';
import { BaseTable } from '@app/components/common/BaseTable/BaseTable';
import { ColumnsType } from 'antd/es/table';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { useTranslation } from 'react-i18next';
import { useMounted } from '@app/hooks/useMounted';
import { BaseSpace } from '@app/components/common/BaseSpace/BaseSpace';
import { EyeOutlined, LockOutlined, UnlockOutlined } from '@ant-design/icons';
import { Col, Form, Image, Input, message, Modal, Row, Select } from 'antd';
import { Book, getBookById, getBook, putLock, putUnlock } from '@app/api/book.api';
import { Loading } from '@app/components/common/Loading/Loading';
import { InputSearch } from '@app/components/header/components/HeaderSearch/HeaderSearch.styles';
import TextArea from 'antd/lib/input/TextArea';
import { Label } from '@app/components/nft-dashboard/Balance/components/TopUpBalanceForm/TopUpBalanceForm.styles';
import { Category, getCategories } from '@app/api/category.api';
import { getlocations, Location } from '@app/api/location.api';

export const NhapSachTable: React.FC = () => {
  const [tableData, setTableData] = useState<{ data: Book[]; loading: boolean }>({
    data: [],
    loading: false,
  });
  const [Locations, setLocations] = useState<{ data: Location[]; loading: boolean }>({
    data: [],
    loading: false,
  });
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedBook, setSelectedBook] = useState<Book | null>(null);
  const { t } = useTranslation();
  const { isMounted } = useMounted();
  const [searchTerm, setSearchTerm] = useState('');
  const [categoryOptions, setCategoryOptions] = useState<string[]>([]);
  const [locationOptions, setLocationOptions] = useState<string[]>([]);
  const [selectedCategory, setSelectedCategory] = useState<string>('Default');
  const [selectedLocation, setSelectedLocation] = useState<string>('Default');

  const fetch = useCallback(() => {
    setTableData((tableData) => ({ ...tableData, loading: true }));
    getBook({ current: 1, pageSize: 10 }).then((res) => {
      if (isMounted.current) {
        const books = res.data;

        const categories = Array.from(new Set(books.map((book) => book.Category)));
        const locations = Array.from(new Set(books.map((book) => book.Location)));

        setCategoryOptions(categories);
        setLocationOptions(locations);

        setTableData({ data: books, loading: false });
      }
    });
    getlocations({ current: 1, pageSize: 10 }).then((res) => {
      if (isMounted.current) {
        setLocations({ data: res.data, loading: false });
      }
    });
  }, [isMounted]);

  useEffect(() => {
    fetch();
  }, [fetch]);

  const handleTableChange = () => {
    fetch();
  };

  const handleDeleteRow = (rowId: any) => {
    setTableData((prevTableData) => ({
      ...prevTableData,
      data: prevTableData.data.filter((item) => item.Barcode !== rowId),
    }));
  };

  const showModal = async (Barcode: string) => {
    setIsModalVisible(true);
    setTableData((prevState) => ({ ...prevState, loading: true }));

    try {
      const book = await getBookById(Barcode);
      setSelectedBook(book);
    } catch (error) {
      console.error('Error fetching book data:', error);
    } finally {
      setTableData((prevState) => ({ ...prevState, loading: false }));
    }
  };

  const handleLock = async (Barcode: string) => {
    try {
      await putLock(Barcode);
      message.success(`Barcode: ${Barcode} đã được khóa!`);
      setTableData((prevTableData) => ({
        ...prevTableData,
        data: prevTableData.data.map((book) => (book.Barcode === Barcode ? { ...book, IsLocked: 1 } : book)),
      }));
    } catch (error) {
      console.error('Error locking book:', error);
      message.error(`Không thể khóa sách với Barcode: ${Barcode}`);
    }
  };

  const handleUnlock = async (Barcode: string) => {
    try {
      await putUnlock(Barcode);
      message.success(`Barcode: ${Barcode} đã được mở khóa!`);
      setTableData((prevTableData) => ({
        ...prevTableData,
        data: prevTableData.data.map((book) => (book.Barcode === Barcode ? { ...book, IsLocked: 0 } : book)),
      }));
    } catch (error) {
      console.error('Error unlocking book:', error);
      message.error(`Không thể mở khóa sách với Barcode: ${Barcode}`);
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setSelectedBook(null);
  };

  const handleSubmit = (values: unknown) => {
    console.log('Received values:', values);
    setIsModalVisible(false);
  };

  const modalChitiet = (
    <Modal
      title={`${selectedBook?.BookName}`}
      open={isModalVisible}
      onCancel={handleCancel}
      footer={null}
      style={{ top: 20 }}
      width={800}
    >
      {selectedBook ? (
        <Form layout="horizontal" onFinish={handleSubmit}>
          <Row gutter={16}>
            <Col span={24} style={{ display: 'flex', justifyContent: 'center' }}>
              <Image src={selectedBook.Thumbnail} width={300} alt="Không có ảnh" />
            </Col>
            <Col span={12}>
              <Form.Item label="Barcode" name="Barcode">
                <Label style={{ color: 'black', fontWeight: 'bold' }}>{selectedBook.Barcode}</Label>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="NXB" name="PublisherName">
                <Label style={{ color: 'black', fontWeight: 'bold' }}>{selectedBook.PublisherName}</Label>
              </Form.Item>
            </Col>
            {/* <Col span={12}>
              <Form.Item label="Ngày nhập sách" name="PublishYear">
                <Label style={{ color: 'black', fontWeight: 'bold' }}>{selectedBook.PublishYear}</Label>
              </Form.Item>
            </Col> */}
            <Col span={12}>
              <Form.Item label="Tên sách" name="BookName">
                <Label style={{ color: 'black', fontWeight: 'bold' }}>{selectedBook.BookName}</Label>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Tác giả" name="Authors">
                <Label style={{ color: 'black', fontWeight: 'bold' }}>{selectedBook.Authors.join(', ')}</Label>
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item label="Năm xuất bản" name="PublishYear">
                <Label style={{ color: 'black', fontWeight: 'bold' }}>{selectedBook.PublishYear}</Label>
              </Form.Item>
            </Col>
            {/* <Col span={6}>
              <Form.Item label="Danh mục" name="Category" initialValue={selectedBook.Category}>
                <Input disabled style={{ backgroundColor: '#EEEEEE', color: 'black' }} />
              </Form.Item>
            </Col> */}
            {/* <Col span={12}>
              <Form.Item label="Vị trí" name="Location">
                <Label style={{ color: 'black', fontWeight: 'bold' }}>{selectedBook.Location}</Label>
              </Form.Item>
            </Col> */}
            {/* <Col span={6}>
              <Form.Item label="Thời hạn mượn" name="loanDuration">
                <Input disabled style={{ backgroundColor: '#EEEEEE', color: 'black' }} />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label="Ghi chú" name="ghichu">
                <TextArea
                  autoSize={{ minRows: 4, maxRows: 8 }}
                  disabled
                  style={{ backgroundColor: '#EEEEEE', color: 'black' }}
                />
              </Form.Item>
            </Col> */}
          </Row>
        </Form>
      ) : (
        <Loading />
      )}
    </Modal>
  );

  const columns: ColumnsType<Book> = [
    {
      title: t('common.barcode'),
      dataIndex: 'Barcode',
      width: '10%',
      align: 'center',
    },
    {
      title: t('common.tensach'),
      dataIndex: 'BookName',
    },
    {
      title: t('common.namxb'),
      dataIndex: 'PublishYear',
      width: '10%',
      align: 'center',
    },
    {
      title: t('common.danhmuc'),
      dataIndex: 'Category',
      width: '10%',
    },
    {
      title: t('common.vitri'),
      dataIndex: 'LocationName',
      width: '10%',
      align: 'center',
    },
    {
      title: t('tables.actions'),
      dataIndex: 'actions',
      width: '10%',
      align: 'center',
      render: (text, record) => {
        return (
          <BaseSpace>
            <BaseButton type="link" onClick={() => showModal(record.Barcode)}>
              <EyeOutlined />
            </BaseButton>
            {record.IsLocked === 0 ? (
              <BaseButton type="link" danger onClick={() => handleLock(record.Barcode)}>
                <LockOutlined />
              </BaseButton>
            ) : (
              <BaseButton type="link" danger onClick={() => handleUnlock(record.Barcode)}>
                <UnlockOutlined />
              </BaseButton>
            )}
          </BaseSpace>
        );
      },
    },
  ];

  const filteredData = tableData.data.filter((item) => {
    const matchesSearchTerm =
      String(item.BookId).toLowerCase().includes(searchTerm.toLowerCase()) ||
      String(item.BookName).toLowerCase().includes(searchTerm.toLowerCase()) ||
      String(item.Location).includes(searchTerm);

    const matchesCategory = selectedCategory === 'Default' || item.Category === selectedCategory;

    const matchesLocation = selectedLocation === 'Default' || item.Location === selectedLocation;

    return matchesSearchTerm && matchesCategory && matchesLocation;
  });

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
  };

  const handleCategoryChange = (value: string) => {
    setSelectedCategory(value);
  };

  const handleLocationChange = (value: string) => {
    setSelectedLocation(value);
  };

  return (
    <div style={{ background: 'white', borderRadius: '10px', margin: '20px', padding: '0 10px' }}>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          height: '80px',
        }}
      >
        <div
          style={{
            marginRight: 'auto',
          }}
        >
          <Select
            defaultValue="Danh mục"
            style={{ width: 170, borderRadius: '8px' }}
            bordered={true}
            dropdownStyle={{ borderRadius: '8px' }}
            onChange={handleCategoryChange}
          >
            <Select.Option value="Default">Tất cả</Select.Option>
            {categoryOptions.map((category) => (
              <Select.Option key={category} value={category}>
                {category}
              </Select.Option>
            ))}
          </Select>

          <Select
            placeholder="Vị trí"
            style={{ width: 130, marginLeft: '10px', borderRadius: '8px' }}
            bordered={true}
            dropdownStyle={{ borderRadius: '8px' }}
            onChange={handleLocationChange}
          >
            <Select.Option value="Default">Tất cả</Select.Option>
            {Locations.data.map((location) => (
              <Select.Option key={location.LocationId + location.LocationName} value={location.LocationId}>
                {location.LocationName}
              </Select.Option>
            ))}
          </Select>
        </div>
        <div style={{ display: 'flex', marginLeft: 'auto' }}>
          <InputSearch
            style={{ width: '350px', marginRight: '10px' }}
            placeholder={t('Tìm kiếm theo ID, Tên sách, Vị trí')}
            onChange={handleSearch}
          />
        </div>
      </div>
      <BaseTable
        columns={columns}
        dataSource={filteredData}
        pagination={{ pageSize: 10 }}
        loading={tableData.loading}
        onChange={handleTableChange}
        scroll={{ x: 800 }}
        bordered
      />
      {modalChitiet}
    </div>
  );
};
