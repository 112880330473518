/* eslint-disable react/jsx-key */
import React, { useState } from 'react';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import { useResponsive } from '@app/hooks/useResponsive';
import * as S from '../../DashboardPages/DashboardPage.styles';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { ThongTinTable } from '@app/components/tables/BasicTable/ThongTinTable';
import { Button, PageHeader } from 'antd';
import ThemSach from './ThemSach';
import NhapSach from './NhapSach';
import '../Pages.css';
import { ImportOutlined, PlusOutlined } from '@ant-design/icons';

const ThongTin: React.FC = () => {
  const { isDesktop } = useResponsive();
  const [isThemSachVisible, setIsThemSachVisible] = useState(false);
  const [isNhapSachVisible, setIsNhapSachVisible] = useState(false);

  const toggleShowThemSach = () => {
    setIsThemSachVisible(!isThemSachVisible);
  };

  const toggleShowNhapSach = () => {
    setIsNhapSachVisible(!isNhapSachVisible);
  };

  const desktopLayout = (
    <>
      <BaseRow>
        <S.LeftSideCol>
          <BaseRow gutter={[60, 60]}>
            <BaseCol span={24}>
              <div className="container">
                <PageHeader
                  className="page-header"
                  title="Quản lý Sách/Thiết bị"
                  footer="Thông tin Sách/Thiết bị"
                  extra={[
                    <Button key="return-btn" type="primary" icon={<ImportOutlined />} onClick={toggleShowNhapSach}>
                      Nhập Sách
                    </Button>,
                    <Button key="them-btn" type="primary" icon={<PlusOutlined />} onClick={toggleShowThemSach}>
                      Thêm Sách Mới
                    </Button>,
                  ]}
                />
                <ThongTinTable />
              </div>
            </BaseCol>
          </BaseRow>
        </S.LeftSideCol>
      </BaseRow>
    </>
  );

  const mobileAndTabletLayout = (
    <BaseRow gutter={[20, 24]}>
      <BaseCol span={24}>
        <div className="container">
          <PageHeader
            className="page-header"
            title="Quản lý Sách/Thiết bị"
            footer="Thông tin Sách/Thiết bị"
            extra={[
              <Button key="return-btn" type="primary" icon={<ImportOutlined />} onClick={toggleShowNhapSach}>
                Nhập Sách
              </Button>,
              <Button key="them-btn" type="primary" icon={<PlusOutlined />} onClick={toggleShowThemSach}>
                Thêm Sách Mới
              </Button>,
            ]}
          />
          <ThongTinTable />
        </div>
      </BaseCol>
    </BaseRow>
  );

  return (
    <>
      <PageTitle>ThongTin</PageTitle>
      {isDesktop ? desktopLayout : mobileAndTabletLayout}
      <ThemSach isModalVisible={isThemSachVisible} toggleShowThemSach={toggleShowThemSach} />
      <NhapSach isModalVisible={isNhapSachVisible} toggleShowNhapSach={toggleShowNhapSach} />
    </>
  );
};

export default ThongTin;
