import React, { useState } from 'react';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import { useResponsive } from '@app/hooks/useResponsive';
import * as S from '../DashboardPages/DashboardPage.styles';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { Button, PageHeader, Modal, Form, Input, DatePicker, Table, Select, Upload, message } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { AdminTable } from '@app/components/tables/BasicTable/AdminTable';
import './Pages.css';

const Admin: React.FC = () => {
  const { isDesktop } = useResponsive();

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [form] = Form.useForm();

  const showModal = () => {
    message.info('Chức năng không khả dụng.');
    // setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    form.resetFields();
  };

  const onFinish = (values: unknown) => {
    console.log('Received values from form: ', values);
    setIsModalVisible(false);
    form.resetFields();
  };

  const modalThem = (
    <Modal title="Thêm Người Dùng" open={isModalVisible} onCancel={handleCancel} footer={null} style={{ top: 10 }}>
      <Form layout="inline">
        <Form.Item
          style={{ width: '30%', marginBottom: '10px' }}
          label="ID"
          name="id"
          rules={[{ required: true, message: 'Vui lòng không bỏ trống!' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          style={{ width: '60%', marginBottom: '10px' }}
          label="Tên Người dùng"
          name="username"
          rules={[{ required: true, message: 'Vui lòng không bỏ trống!' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          style={{ width: '30%', marginBottom: '10px' }}
          label="SĐT"
          name="phone"
          rules={[{ required: true, message: 'Vui lòng không bỏ trống!' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          style={{ width: '60%', marginBottom: '10px' }}
          label="Email"
          name="email"
          rules={[{ required: true, message: 'Vui lòng không bỏ trống!' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          style={{ width: '25%', marginBottom: '10px' }}
          label="Giới tính"
          name="gender"
          rules={[{ required: true, message: 'Vui lòng chọn giới tính!' }]}
        >
          <Select>
            <Select.Option value="Nam">Nam</Select.Option>
            <Select.Option value="Nữ">Nữ</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item
          style={{ width: '65%', marginBottom: '10px' }}
          label="Mật khẩu"
          name="password"
          rules={[{ required: true, message: 'Vui lòng không bỏ trống!' }]}
        >
          <Input.Password />
        </Form.Item>
        <Form.Item
          style={{ width: '25%', marginBottom: '10px' }}
          label="Phân loại"
          name="role"
          rules={[{ required: true, message: 'Vui lòng chọn phân loại!' }]}
        >
          <Select>
            <Select.Option value="Hs/Sv">Hs/Sv</Select.Option>
            <Select.Option value="Giáo viên">Giáo viên</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item style={{ width: '100%', borderTop: '1px solid #d9d9d9' }}>
          <Button
            type="primary"
            htmlType="submit"
            size="middle"
            style={{ width: '20%', marginLeft: '80%', marginTop: '10px' }}
          >
            <PlusOutlined /> Thêm
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );

  const desktopLayout = (
    <>
      <BaseRow>
        <S.LeftSideCol>
          <BaseRow gutter={[60, 60]}>
            <BaseCol span={24}>
              <div className="container">
                <PageHeader
                  className="page-header"
                  title="Admin"
                  footer="Quản lý các tài khoản thành viên"
                  extra={[
                    <Button key="1" type="primary" icon={<PlusOutlined />} onClick={showModal}>
                      Tạo Người Dùng
                    </Button>,
                  ]}
                />
                <AdminTable />
              </div>
            </BaseCol>
          </BaseRow>
        </S.LeftSideCol>
      </BaseRow>
    </>
  );

  const mobileAndTabletLayout = (
    <BaseRow gutter={[20, 24]}>
      <BaseCol span={24}>
        <div className="container">
          <PageHeader
            className="page-header"
            title="Admin"
            footer="Quản lý các tài khoản thành viên"
            // extra={[
            //   <Button key="1" type="primary" icon={<PlusOutlined />} onClick={showModal}>
            //     Tạo Người Dùng
            //   </Button>,
            // ]}
          />
          <AdminTable />
        </div>
      </BaseCol>
    </BaseRow>
  );

  return (
    <>
      <PageTitle>Admin</PageTitle>
      {isDesktop ? desktopLayout : mobileAndTabletLayout}
      {modalThem}
    </>
  );
};

export default Admin;
