import React, { useEffect, useState, useCallback } from 'react';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import { useResponsive } from '@app/hooks/useResponsive';
import { Card, Col, Form, Input, Modal, Row, Tooltip } from 'antd';
import * as S from '../DashboardPages/DashboardPage.styles';
import './Dashboard.css';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { BarChartOutlined, BookFilled, EyeOutlined, UserOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useMounted } from '@app/hooks/useMounted';
import { getNewAdd, getOverDue, getThongKe, NewAdd, OverDue, ThongKe } from '@app/api/dashboard.api';
import { ColumnsType } from 'antd/lib/table';
import { BaseTable } from '@app/components/common/BaseTable/BaseTable';
import { Pagination } from '@app/api/table.api';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { Loading } from '@app/components/common/Loading/Loading';
import { Label } from '@app/components/nft-dashboard/Balance/components/TopUpBalanceForm/TopUpBalanceForm.styles';
import { useNavigate } from 'react-router-dom';

const initialPagination: Pagination = {
  current: 1,
  pageSize: 5,
};

const Dashboard: React.FC = () => {
  const { isDesktop } = useResponsive();
  const [newLoan, setNewLoan] = useState<{ data: NewAdd[]; pagination: Pagination; loading: boolean }>({
    data: [],
    pagination: initialPagination,
    loading: false,
  });
  const [newOverDue, setOverDue] = useState<{ data: OverDue[]; pagination: Pagination; loading: boolean }>({
    data: [],
    pagination: initialPagination,
    loading: false,
  });
  const [thongKeData, setThongKeData] = useState<ThongKe | null>(null);
  const [isModalMuonVisible, setIsModalMuonVisible] = useState(false);
  const [isModalQuaHanVisible, setIsModalQuaHanVisible] = useState(false);
  const { t } = useTranslation();
  const { isMounted } = useMounted();
  const navigate = useNavigate();

  const fetch = useCallback(
    (pagination: Pagination) => {
      setNewLoan((prevTableData) => ({ ...prevTableData, loading: true }));
      setThongKeData(null);
      setOverDue((prevTableData) => ({ ...prevTableData, loading: true }));

      getNewAdd(pagination)
        .then((res) => {
          if (isMounted.current) {
            setNewLoan({ data: res.data, pagination: res.pagination, loading: false });
          }
        })
        .catch(() => {
          if (isMounted.current) {
            setNewLoan((prevTableData) => ({ ...prevTableData, loading: false }));
          }
        });

      getOverDue(pagination)
        .then((res) => {
          if (isMounted.current) {
            setOverDue({ data: res.data, pagination: res.pagination, loading: false });
            console.log(res.data);
          }
        })
        .catch(() => {
          if (isMounted.current) {
            setOverDue((prevTableData) => ({ ...prevTableData, loading: false }));
          }
        });

      getThongKe()
        .then((res) => {
          if (isMounted.current) {
            setThongKeData(res);
          }
        })
        .catch(() => {
          if (isMounted.current) {
            setThongKeData(null);
          }
        });
    },
    [isMounted],
  );

  useEffect(() => {
    fetch(initialPagination);
  }, [fetch]);

  const newBooksColumns: ColumnsType<NewAdd> = [
    {
      key: 'dashboard-#',
      title: '#',
      render: (text, record, index) => index + 1,
      align: 'center',
      width: '5%',
    },
    {
      title: t('common.hoten'),
      dataIndex: 'MemberName',
    },
    {
      title: t('common.phanloai'),
      dataIndex: 'MemberType',
      align: 'center',
    },
    {
      title: t('common.ngaythamgia'),
      dataIndex: 'MemberSinceDate',
      align: 'center',
      render: (record) => new Date(record).toLocaleDateString('vi-VN'),
    },
    // {
    //   key: 'dashboard-actions',
    //   title: t('tables.actions'),
    //   dataIndex: 'actions',
    //   align: 'center',
    //   width: '15%',
    //   render: (record) => (
    //     <BaseButton
    //       type="ghost"
    //       onClick={showModalMuon}
    //       icon={<EyeOutlined style={{ padding: 0 }} />}
    //       style={{ width: '100%' }}
    //     />
    //   ),
    // },
  ];

  const overdueUsersColumns: ColumnsType<OverDue> = [
    {
      title: '#',
      render: (text, record, index) => index + 1,
      align: 'center',
      width: '5%',
    },
    {
      title: t('common.hoten'),
      dataIndex: 'MemberName',
    },
    {
      title: t('common.sdt'),
      dataIndex: 'PhoneNumber',
    },
    {
      title: 'Barcode nợ',
      dataIndex: 'DueDate',
      // render: (record) => new Date(record).toLocaleDateString('vi-VN'),
    },
    // {
    //   title: t('tables.actions'),
    //   dataIndex: 'actions',
    //   width: '15%',
    //   render: (record) => (
    //     <BaseButton
    //       type="ghost"
    //       onClick={showModalMuon}
    //       icon={<EyeOutlined style={{ padding: 0 }} />}
    //       style={{ width: '100%' }}
    //     />
    //   ),
    // },
  ];

  const modalColumns: ColumnsType<NewAdd> = [
    {
      title: '#',
      render: (index) => index + 1,
      align: 'center',
    },
    {
      title: t('Thông tin sách'),
      dataIndex: 'BookName',
    },
    {
      title: t('Thời gian mượn'),
      dataIndex: 'PublisherName',
      align: 'center',
      render: (record) => new Date(record).toLocaleDateString('vi-VN'),
    },
    {
      title: t('Số ngày quá hạn'),
      dataIndex: 'PublishYear',
      align: 'center',
    },
  ];

  const renderStatistics = () => (
    <BaseRow className="thongkenhanh" gutter={[30, 30]}>
      <BaseCol id="name" span={24}>
        <BarChartOutlined />
        <span style={{ marginLeft: '5px' }}>Thống kê nhanh</span>
      </BaseCol>
      {thongKeData ? (
        <>
          <Tooltip title="Chuyển sang tồn kho">
            <BaseCol span={6} onClick={() => navigate('/tonkho')}>
              <Card id="giatri" style={{ borderBottom: '10px solid red', cursor: 'pointer' }}>
                <p style={{ fontSize: '15px' }}>Tổng số sách</p>
                <p
                  style={{
                    fontSize: '30px',
                    fontWeight: 'bold',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  {thongKeData.TotalBook.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}{' '}
                  <BookFilled style={{ justifyContent: 'end', color: 'red' }} />
                </p>
              </Card>
            </BaseCol>
          </Tooltip>
          <Tooltip title="Chuyển sang quản lý sách">
            <BaseCol span={6} onClick={() => navigate('/thongtin')}>
              <Card id="giatri" style={{ borderBottom: '10px solid green', cursor: 'pointer' }}>
                <p style={{ fontSize: '15px' }}>Sẵn sàng cho mượn</p>
                <p
                  style={{
                    fontSize: '30px',
                    fontWeight: 'bold',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  {thongKeData.TotalBookAvailable.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}{' '}
                  <BookFilled style={{ justifyContent: 'end', color: 'green' }} />
                </p>
              </Card>
            </BaseCol>
          </Tooltip>
          <Tooltip title="Chuyển sang mượn sách">
            <BaseCol span={6} onClick={() => navigate('/muonsach')}>
              <Card id="giatri" style={{ borderBottom: '10px solid #ffcc00', cursor: 'pointer' }}>
                <p style={{ fontSize: '15px' }}>Sách đang mượn</p>
                <p
                  style={{
                    fontSize: '30px',
                    fontWeight: 'bold',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  {thongKeData.TotalBookOnLoan.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}{' '}
                  <UserOutlined style={{ justifyContent: 'end', color: '#ffcc00' }} />
                </p>
              </Card>
            </BaseCol>
          </Tooltip>
          <Tooltip title="Chuyển sang khách hàng">
            <BaseCol span={6} onClick={() => navigate('/customer')}>
              <Card id="giatri" style={{ borderBottom: '10px solid #d96f39', cursor: 'pointer' }}>
                <p style={{ fontSize: '15px' }}>Tổng số thành viên</p>
                <p
                  style={{
                    fontSize: '30px',
                    fontWeight: 'bold',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  {thongKeData.TotalMember.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}{' '}
                  <UserOutlined style={{ justifyContent: 'end', color: '#d96f39' }} />
                </p>
              </Card>
            </BaseCol>
          </Tooltip>
        </>
      ) : (
        <Loading />
      )}
    </BaseRow>
  );

  const renderTables = () => (
    <BaseRow gutter={[20, 24]} style={{ display: 'flex', justifyContent: 'space-between' }}>
      <Tooltip title="Chuyển sang mượn sách">
        <BaseCol className="moithem" onClick={() => navigate('/muonsach')} style={{ cursor: 'pointer' }}>
          <span>Danh sách người mượn mới (Top {newLoan.data.length})</span>
          <BaseTable
            dataSource={newLoan.data}
            columns={newBooksColumns}
            pagination={false}
            loading={newLoan.loading}
            bordered
            style={{ marginTop: '10px' }}
          />
        </BaseCol>
      </Tooltip>
      <Tooltip title="Chuyển sang mượn sách quá hạn">
        <BaseCol className="quahan" onClick={() => navigate('/muonsach?filter=overdue')} style={{ cursor: 'pointer' }}>
          <span>Người mượn quá hạn</span>
          <BaseTable
            dataSource={newOverDue.data}
            columns={overdueUsersColumns}
            pagination={false}
            bordered
            loading={newOverDue.loading}
            style={{ marginTop: '10px' }}
          />
        </BaseCol>
      </Tooltip>
    </BaseRow>
  );

  const showModalMuon = () => {
    setIsModalMuonVisible(true);
  };

  const showModalQuaHan = () => {
    setIsModalQuaHanVisible(true);
  };

  const handleCancelMuon = () => {
    setIsModalMuonVisible(false);
  };

  const handleCancelQuaHan = () => {
    setIsModalQuaHanVisible(false);
  };

  const ChiTietQuaHan = (
    <Modal
      width={800}
      title={`Chi tiết người mươn quá hạn`}
      visible={isModalQuaHanVisible}
      onCancel={handleCancelQuaHan}
      footer={null}
      style={{ top: 20 }}
    >
      <Form layout="horizontal">
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item label="Họ tên:" name="MemberName">
              <Label style={{ color: 'black', fontWeight: 'bold' }}></Label>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Số điện thoại:" name="PhoneNumber">
              <Label style={{ color: 'black', fontWeight: 'bold' }}></Label>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Email:" name="email">
              <Label style={{ color: 'black', fontWeight: 'bold' }}></Label>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="CCCD:" name="CCCD">
              <Label style={{ color: 'black', fontWeight: 'bold' }}></Label>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Mã SV/GV:" name="masv">
              <Label style={{ color: 'black', fontWeight: 'bold' }}></Label>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Phân loại:" name="category">
              <Label style={{ color: 'black', fontWeight: 'bold' }}></Label>
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item label="Sách mượn quá hạn: " />
            <BaseTable style={{ width: '100%' }} columns={modalColumns} pagination={false} />
          </Col>
        </Row>
      </Form>
    </Modal>
  );

  const formatDateToVN = (dateString: string | number | Date) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('vi-VN');
  };

  const ChiTietMuon = (
    <Modal
      width={800}
      title={`Chi tiết người mượn mới`}
      visible={isModalMuonVisible}
      onCancel={handleCancelMuon}
      footer={null}
      style={{ top: 20 }}
    >
      <Form layout="horizontal">
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item label="Họ tên:" name="MemberName">
              <Label style={{ color: 'black', fontWeight: 'bold' }}></Label>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Số điện thoại:" name="PhoneNumber">
              <Label style={{ color: 'black', fontWeight: 'bold' }}></Label>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Email:" name="email">
              <Label style={{ color: 'black', fontWeight: 'bold' }}></Label>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="CCCD:" name="CCCD">
              <Label style={{ color: 'black', fontWeight: 'bold' }}></Label>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Mã SV/GV:" name="masv">
              <Label style={{ color: 'black', fontWeight: 'bold' }}></Label>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Phân loại:" name="category">
              <Label style={{ color: 'black', fontWeight: 'bold' }}></Label>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Ngày tham gia:" name="thamgia">
              {/* initialValue={formatDateToVN()} */}
              <Label style={{ color: 'black', fontWeight: 'bold' }}></Label>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );

  const desktopLayout = (
    <BaseRow>
      <S.LeftSideCol>
        <BaseRow gutter={[60, 60]}>
          <BaseCol span={24}>
            <div style={{ margin: '20px 35px' }}>
              {renderStatistics()}
              {renderTables()}
            </div>
          </BaseCol>
        </BaseRow>
      </S.LeftSideCol>
      {ChiTietMuon}
      {ChiTietQuaHan}
    </BaseRow>
  );

  const mobileAndTabletLayout = (
    <BaseRow gutter={[20, 24]}>
      <BaseCol span={24}>
        <h1>THỐNG KÊ NHANH</h1>
        {renderStatistics()}
      </BaseCol>
      <BaseCol span={24}>
        <h2>Sách mới thêm</h2>
        <BaseTable
          dataSource={newLoan.data}
          columns={newBooksColumns}
          pagination={newLoan.pagination}
          loading={newLoan.loading}
        />
      </BaseCol>
      <BaseCol span={24}>
        <h2>Người dùng quá hạn</h2>
        <BaseTable
          dataSource={newOverDue.data}
          columns={overdueUsersColumns}
          pagination={newOverDue.pagination}
          bordered
          loading={newOverDue.loading}
        />
      </BaseCol>
      {ChiTietQuaHan}
      {ChiTietMuon}
    </BaseRow>
  );

  return (
    <>
      <PageTitle>Dashboard</PageTitle>
      {isDesktop ? desktopLayout : mobileAndTabletLayout}
    </>
  );
};

export default Dashboard;
