/* eslint-disable react-hooks/exhaustive-deps */
import { postMuon } from '@app/api/muon.api';
import { getMemberById } from '@app/api/thanhvien.api';
import { Values } from '@app/components/common/charts/Legend/Legend.styles';
import { Label } from '@app/components/nft-dashboard/Balance/components/TopUpBalanceForm/TopUpBalanceForm.styles';
import { Button, Col, Form, Input, message, Modal, Row } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import moment from 'moment';
import { useEffect, useState } from 'react';

type Props = {
  isModalVisible: boolean;
  toggleShowModal: () => void;
};

const date = new Date(new Date().setDate(new Date().getDate() + 7));
const formattedDate =
  String(date.getDate()).padStart(2, '0') +
  '-' +
  String(date.getMonth() + 1).padStart(2, '0') +
  '-' +
  date.getFullYear();

const SEVEN_DAYS_LATER = formattedDate;

function CreateModal(props: Props) {
  const [isClicked, setIsClicked] = useState(false);
  const [form] = Form.useForm();
  const [memberId, setMemberId] = useState('');
  const [debouncedTerm, setDebouncedTerm] = useState('');
  const [memberName, setMemberName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');

  useEffect(() => {
    const timerId = setTimeout(() => {
      setDebouncedTerm(form.getFieldValue('MemberId'));
    }, 500);

    return () => {
      clearTimeout(timerId);
    };
  }, [memberId]);

  useEffect(() => {
    if (debouncedTerm) {
      const fetchResults = async () => {
        try {
          const memberInfo = await getMemberById(memberId);
          setMemberName(memberInfo.MemberName);
          setPhoneNumber(memberInfo.PhoneNumber);
          form.setFieldsValue({
            MemberName: memberInfo.MemberName,
            PhoneNumber: memberInfo.PhoneNumber,
          });
          setMemberId(form.getFieldValue('MemberId'));
        } catch (error) {
          console.error('Lỗi khi lấy thông tin thành viên:', error);
        }
      };
      fetchResults();
    }
  }, [debouncedTerm]);

  const handleDateChange = (value: string) => {
    if (value.length > 10) return;

    const numbers = value.replace(/[^0-9]/g, '');

    let formattedValue = '';

    if (numbers.length >= 2) {
      formattedValue += numbers.slice(0, 2);
      if (numbers.length > 2) {
        formattedValue += '-' + numbers.slice(2, 4);
        if (numbers.length > 4) {
          formattedValue += '-' + numbers.slice(4, 8);
        }
      }
    } else {
      formattedValue = numbers;
    }

    form.setFieldsValue({ DueDate: formattedValue });
  };

  const onFinish = async (values: any) => {
    try {
      setIsClicked(true);
      const transformedValues = {
        ...values,
        MemberId: values.MemberId,
        LoanDate: moment().format('YYYY-MM-DD'),
        DueDate: values.DueDate ? moment(values.DueDate, 'DD/MM/YYYY').format('YYYY-MM-DD') : null,
        Barcodes: values.Barcodes.split(',').map((barcode: string) => barcode.trim()),
      };

      const response = await postMuon(transformedValues);
      props.toggleShowModal();
      message.success('Danh mục sách đã được thêm thành công.');
      form.resetFields();
      setIsClicked(false);
      window.location.reload();
    } catch (error: any) {
      setIsClicked(false);
    }
  };

  const handleBarcodeChange = async (value: string) => {
    try {
      const _barcodes = value.split(',').map((barcode) => barcode.trim());
    } catch (error) {
      console.error('Lỗi khi lấy thông tin sách:', error);
    }
  };

  return (
    <Modal
      title="Thêm Người Mượn"
      open={props.isModalVisible}
      onCancel={() => {
        form.resetFields();
        props.toggleShowModal();
      }}
      footer={
        <div style={{ textAlign: 'center' }}>
          <Button
            type="primary"
            htmlType="submit"
            onClick={() => {
              form
                .validateFields()
                .then((values) => {
                  onFinish(values);
                })
                .catch((info) => {
                  console.log('Validate Failed:', info);
                });
            }}
            size="middle"
            disabled={isClicked}
            loading={isClicked}
          >
            Xác Nhận
          </Button>
        </div>
      }
      style={{ top: 20 }}
      width={800}
    >
      <Form layout="vertical" form={form} onFinish={onFinish}>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              label="Mã Thành Viên"
              name="MemberId"
              rules={[{ required: true, message: 'Vui lòng không bỏ trống!' }]}
            >
              <Input onChange={(e) => setMemberId(e.target.value)} value={memberId} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Ngày Trả (Mặc định 7 ngày từ ngày mượn)"
              name="DueDate"
              rules={[{ required: true, message: 'Vui lòng chọn ngày trả!' }]}
              initialValue={SEVEN_DAYS_LATER}
            >
              <Input onChange={(e) => handleDateChange(e.target.value)} maxLength={10} placeholder="DD/MM/YYYY" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Họ và Tên" name="MemberName">
              <Label>{form.getFieldValue('MemberName') || 'N/A'}</Label>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Số điện thoại" name="PhoneNumber">
              <Label>{form.getFieldValue('PhoneNumber') || 'N/A'}</Label>
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              label="Barcode Sách được mượn (Mỗi sách nhau bởi dấu phẩy) (000001, 000002,...)"
              name="Barcodes"
              rules={[{ required: true, message: 'Vui lòng không bỏ trống!' }]}
            >
              <Input.TextArea
                onChange={(e) => handleBarcodeChange(e.target.value)}
                autoSize={{ minRows: 1, maxRows: 6 }}
                placeholder="Nhập tối đa 5 phần tử, cách nhau bởi dấu phẩy"
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item label="Ghi chú" name="Notes" initialValue={null}>
              <TextArea autoSize={{ minRows: 4, maxRows: 10 }} />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}

export default CreateModal;
