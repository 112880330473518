/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback, useEffect, useState } from 'react';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import { useResponsive } from '@app/hooks/useResponsive';
import * as S from '../DashboardPages/DashboardPage.styles';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { PlusOutlined, UploadOutlined } from '@ant-design/icons';
import { Button, PageHeader, Modal, Form, Input, Row, Col, Upload, Select, message } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { BiblioInput, storeBookBiblio, storeBookItem } from '@app/api/book.api';
import { UploadFile } from 'antd/es/upload/interface';
import { ThemMoiSachTable } from '@app/components/tables/BasicTable/ThemMoiSachTable';
import { useMounted } from '@app/hooks/useMounted';
import { Category, getCategories } from '@app/api/category.api';
import { getlocations, Location } from '@app/api/location.api';

const ThemSach: React.FC = () => {
  const [Categorys, setCategorys] = useState<{ data: Category[]; loading: boolean }>({
    data: [],
    loading: false,
  });
  const [Locations, setLocations] = useState<{ data: Location[]; loading: boolean }>({
    data: [],
    loading: false,
  });
  const { isDesktop } = useResponsive();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [form] = Form.useForm();
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const { isMounted } = useMounted();
  const [selectedCategory, setSelectedCategory] = useState<string>('Default');
  const [selectedLocation, setSelectedLocation] = useState<string>('Default');

  const fetch = useCallback(() => {
    setCategorys((tableData) => ({ ...tableData, loading: true }));
    getCategories({ current: 1, pageSize: 10 }).then((res) => {
      if (isMounted.current) {
        setCategorys({ data: res.data, loading: false });
      }
    });

    setLocations((tableData) => ({ ...tableData, loading: true }));
    getlocations({ current: 1, pageSize: 10 }).then((res) => {
      if (isMounted.current) {
        setLocations({ data: res.data, loading: false });
      }
    });
  }, [isMounted]);

  useEffect(() => {
    fetch();
  }, [fetch]);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    form.resetFields();
    setFileList([]);
  };

  const handleFileChange = ({ fileList }: { fileList: UploadFile[] }) => {
    setFileList(fileList);
  };

  const onFinish = async (values: any) => {
    try {
      if (fileList.length === 0) {
        message.error('Ảnh bìa sách là bắt buộc.');
        return;
      }

      const formData = new FormData();
      formData.append('ISBN', values.ISBN);
      formData.append('BookName', values.BookName);
      formData.append('AuthorName', values.Authors);
      formData.append('PublisherName', values.Publisher);
      formData.append('PublishYear', values.PublishYear);
      formData.append('CategoryId', values.CategoryId);
      formData.append('LocationId', values.LocationId);
      formData.append('Notes', values.Notes);

      const thumbnailFile = fileList[0].originFileObj as File;
      formData.append('Thumbnail', thumbnailFile);
      // console.log('Form data:', formData, values);
      const response = await storeBookBiblio(formData);
      console.log('Response:', response);

      setIsModalVisible(false);
      form.resetFields();
      setFileList([]);
      message.success('Sách đã được thêm thành công.');
      window.location.reload();
    } catch (error: any) {
      if (error.response) {
        console.error('API Error:', error.response.data);
        message.error(`Có lỗi API xảy ra: ${error.response.data}`);
      } else {
        console.error('Error submitting form:', error.message);
        message.error(`Có lỗi xảy ra: ${error.message}`);
      }
    }
  };

  const handleCategoryChange = (value: string) => {
    setSelectedCategory(value);
  };

  const handleLocationChange = (value: string) => {
    setSelectedLocation(value);
  };

  const modalThem = (
    <Modal
      title="Thêm Sách"
      open={isModalVisible}
      onCancel={handleCancel}
      footer={null}
      style={{ top: 20 }}
      width={800}
    >
      <Form layout="vertical" form={form} onFinish={onFinish}>
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item label="ISBN" name="ISBN" rules={[{ required: true, message: 'Vui lòng không bỏ trống!' }]}>
              <Input style={{ fontWeight: 'normal' }} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              label="Tên sách"
              name="BookName"
              rules={[{ required: true, message: 'Vui lòng không bỏ trống!' }]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label="Tác giả" name="Authors">
              <Input />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label="Nhà xuất bản" name="Publisher">
              <Input />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label="Năm xuất bản" name="PublishYear">
              <Input type="number" />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label="Danh mục" name="CategoryId">
              <Select value={selectedCategory} defaultValue={selectedCategory} onChange={handleCategoryChange}>
                <Select.Option value="Default">Danh mục</Select.Option>
                {Categorys.data.map((category) => (
                  <Select.Option key={category.CategoryCode} value={category.CategoryId}>
                    {category.CategoryName}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={18}>
            <Form.Item label="Ghi chú" name="Notes">
              <TextArea autoSize={{ minRows: 4 }} />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label="Ảnh bìa sách" name="Thumbnail">
              <Upload
                listType="picture"
                maxCount={1}
                fileList={fileList}
                beforeUpload={() => false}
                onChange={handleFileChange}
              >
                <Button icon={<UploadOutlined />}>Chọn tệp</Button>
              </Upload>
            </Form.Item>
          </Col>
        </Row>

        <Form.Item style={{ marginTop: '20px' }}>
          <Button style={{ width: '100%' }} type="primary" htmlType="submit" size="middle">
            Xác Nhận Thêm Sách
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );

  const desktopLayout = (
    <>
      <BaseRow>
        <S.LeftSideCol>
          <BaseRow gutter={[60, 60]}>
            <BaseCol span={24}>
              <div className="container">
                <PageHeader
                  className="page-header"
                  title="Quản lý Sách/Thiết bị"
                  footer="Thêm sách mới"
                  extra={[
                    <Button key="1" type="primary" icon={<PlusOutlined />} onClick={showModal}>
                      Thêm sách
                    </Button>,
                  ]}
                />
                <ThemMoiSachTable />
              </div>
            </BaseCol>
          </BaseRow>
        </S.LeftSideCol>
      </BaseRow>
    </>
  );

  const mobileAndTabletLayout = (
    <BaseRow gutter={[20, 24]}>
      <BaseCol span={24}>
        <div className="container">
          <PageHeader
            className="page-header"
            title="Quản lý Sách/Thiết bị"
            footer="Thêm sách mới"
            extra={[
              <Button key="1" type="primary" icon={<PlusOutlined />} onClick={showModal}>
                Thêm sách
              </Button>,
            ]}
          />
          <ThemMoiSachTable />
        </div>
      </BaseCol>
    </BaseRow>
  );

  return (
    <>
      <PageTitle>ThemSach</PageTitle>
      {isDesktop ? desktopLayout : mobileAndTabletLayout}
      {modalThem}
    </>
  );
};

export default ThemSach;
