import React, { useCallback, useEffect, useState } from 'react';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import { useResponsive } from '@app/hooks/useResponsive';
import * as S from '../DashboardPages/DashboardPage.styles';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { CloseOutlined, PlusOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, PageHeader, Modal, Form, Input, Select, message, Row, Col } from 'antd';
import './Pages.css';
import { NhapSachTable } from '@app/components/tables/BasicTable/NhapSachTable';
import { ItemInput, storeBookItem } from '@app/api/book.api';
import { Biblio, getBiblioByKeyword } from '@app/api/themsach.api';
import { getlocations, Location } from '@app/api/location.api';
import { useMounted } from '@app/hooks/useMounted';
import { useDebounce } from 'use-debounce';

const NhapSach: React.FC = () => {
  const [Locations, setLocations] = useState<{ data: Location[]; loading: boolean }>({
    data: [],
    loading: false,
  });
  const { isDesktop } = useResponsive();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [bookList, setBookList] = useState([{ id: 1, barcode: '' }]);
  const { isMounted } = useMounted();
  const [form] = Form.useForm();
  const [BiblioResult, setBiblioResult] = useState<Biblio[]>([]);
  const [selectedBiblio, setSelectedBiblio] = useState<Biblio | null>(null);
  const [selectedLocation, setSelectedLocation] = useState<string>('Default');
  const [keyword, setKeyword] = useState<string>('');
  const [debouncedKeyword] = useDebounce(keyword, 1000);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    form.resetFields();
    setBookList([{ id: 1, barcode: '' }]);
  };

  const fetch = useCallback(() => {
    setLocations((tableData) => ({ ...tableData, loading: true }));
    getlocations({ current: 1, pageSize: 10 }).then((res) => {
      if (isMounted.current) {
        setLocations({ data: res.data, loading: false });
      }
    });
  }, [isMounted]);

  useEffect(() => {
    fetch();
  }, [fetch]);

  useEffect(() => {
    if (debouncedKeyword) {
      fetchBooks(debouncedKeyword);
    }
  }, [debouncedKeyword]);

  const onFinish = async (values: any) => {
    try {
      if (!selectedBiblio) return;

      const data: ItemInput = {
        Items: [
          {
            Barcode: bookList.map((book) => book.barcode),
            BookId: selectedBiblio.BookId,
            LocationId: values.LocationId,
          },
        ],
      };

      console.log('Received values from form: ', data);

      const response = await storeBookItem(data);
      console.log('Response:', response);

      form.resetFields();
      setBookList([{ id: 1, barcode: '' }]);
      message.success('Danh mục sách đã được thêm thành công.');
      setIsModalVisible(false);
      window.location.reload();
    } catch (error: any) {
      if (error.response) {
        console.error('API Error:', error.response.data);
        message.error(`Có lỗi xảy ra: ${error.response.data}`);
      } else {
        console.error('Error submitting form:', error.message);
        message.error(`Có lỗi xảy ra: ${error.message}`);
      }
    }
  };

  const addBookRow = () => {
    setBookList([...bookList, { id: bookList.length + 1, barcode: '' }]);
  };

  const removeBookRow = (id: number) => {
    setBookList(bookList.filter((book) => book.id !== id));
  };

  const fetchBooks = async (Keyword: string) => {
    console.log(Keyword);
    try {
      const data: Biblio[] = await getBiblioByKeyword(Keyword);
      setBiblioResult(data);
    } catch (error) {
      console.error('Error fetching loan data:', error);
    }
  };

  const handleLocationChange = (value: string) => {
    setSelectedLocation(value);
  };

  const modalThem = (
    <Modal
      title="Thêm sách vào thư viện"
      open={isModalVisible}
      onCancel={handleCancel}
      footer={null}
      style={{ top: 20 }}
      width={800}
    >
      <Form layout="vertical" form={form} onFinish={onFinish}>
        <Row gutter={16}>
          <Col span={16}>
            <Form.Item
              label="Tên sách"
              name={`BookName`}
              rules={[{ required: true, message: 'Vui lý nhập tên sách!' }]}
            >
              <Select
                showSearch
                onSearch={(value) => setKeyword(value)}
                onChange={(value) => {
                  console.log(value);
                  setSelectedBiblio(JSON.parse(value));
                }}
                placeholder="Nhập tên sách"
                filterOption={false}
                notFoundContent={null}
              >
                {BiblioResult.map((biblio) => (
                  <Select.Option value={JSON.stringify(biblio)} key={biblio.BookId}>
                    {biblio.BookName}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="Vị trí" name="LocationId" rules={[{ required: true, message: 'Vui lòng chọn vị trí!' }]}>
              <Select value={selectedLocation} defaultValue={selectedLocation} onChange={handleLocationChange}>
                <Select.Option value="Default">Vị trí</Select.Option>
                {Locations.data.map((location) => (
                  <Select.Option key={location.LocationName} value={location.LocationId}>
                    {location.LocationName}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="Năm xuất bản">
              <Input
                value={selectedBiblio?.PublishYear}
                disabled
                style={{ backgroundColor: '#EEEEEE', color: 'black' }}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="Nhà xuất bản">
              <Input
                value={selectedBiblio?.PublisherName}
                disabled
                style={{ backgroundColor: '#EEEEEE', color: 'black' }}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="Tác giả">
              <Input
                value={selectedBiblio?.Authors.join(' - ')}
                disabled
                style={{ backgroundColor: '#EEEEEE', color: 'black' }}
              />
            </Form.Item>
          </Col>
        </Row>

        {bookList.map((book, index) => (
          <div key={book.id} style={{ marginBottom: '10px', borderBottom: '1px solid #f0f0f0', paddingBottom: '10px' }}>
            <Row gutter={16} align={'bottom'}>
              <Col span={24}>
                <Form.Item
                  label={`Barcode sách ${index + 1}`}
                  name={`barcode-${book.id}`}
                  rules={[{ required: true, message: 'Vui lòng nhập Barcode!' }]}
                >
                  <Input
                    style={{ width: '100%' }}
                    value={book.barcode}
                    suffix={
                      <CloseOutlined type="text" style={{ color: 'red' }} onClick={() => removeBookRow(book.id)} />
                    }
                    onChange={(e) => {
                      const newList = [...bookList];
                      newList[index].barcode = e.target.value;
                      setBookList(newList);
                    }}
                  />
                </Form.Item>
              </Col>
            </Row>
          </div>
        ))}

        <Form.Item style={{ marginTop: '20px' }}>
          <Row gutter={16}>
            <Col span={12}>
              <Button type="ghost" onClick={addBookRow} style={{ width: '100%', marginBottom: '20px' }}>
                Thêm Barcode
              </Button>
            </Col>
            <Col span={12}>
              <Button style={{ width: '100%' }} type="primary" htmlType="submit" size="middle">
                Xác Nhận Thêm Vào Danh Mục
              </Button>
            </Col>
          </Row>
        </Form.Item>
      </Form>
    </Modal>
  );

  const desktopLayout = (
    <>
      <BaseRow>
        <S.LeftSideCol>
          <BaseRow gutter={[60, 60]}>
            <BaseCol span={24}>
              <div className="container">
                <PageHeader
                  className="page-header"
                  title="Quản lý Sách/Thiết bị"
                  footer="Nhập sách vào kho"
                  extra={[
                    <Button key="1" type="primary" icon={<PlusOutlined />} onClick={showModal}>
                      Thêm danh mục sách
                    </Button>,
                  ]}
                />
                <NhapSachTable />
              </div>
            </BaseCol>
          </BaseRow>
        </S.LeftSideCol>
      </BaseRow>
    </>
  );

  const mobileAndTabletLayout = (
    <BaseRow gutter={[20, 24]}>
      <BaseCol span={24}>
        <div className="container">
          <PageHeader
            className="page-header"
            title="Quản lý Sách/Thiết bị"
            footer="Nhập sách vào kho"
            extra={[
              <Button key="1" type="primary" icon={<PlusOutlined />} onClick={showModal}>
                Thêm danh mục sách
              </Button>,
            ]}
          />
          <NhapSachTable />
        </div>
      </BaseCol>
    </BaseRow>
  );

  return (
    <>
      <PageTitle>NhapSach</PageTitle>
      {isDesktop ? desktopLayout : mobileAndTabletLayout}
      {modalThem}
    </>
  );
};

export default NhapSach;
