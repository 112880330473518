/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState, useCallback } from 'react';
import { BaseTable } from '@app/components/common/BaseTable/BaseTable';
import { ColumnsType } from 'antd/es/table';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { useTranslation } from 'react-i18next';
import { useMounted } from '@app/hooks/useMounted';
import { BaseSpace } from '@app/components/common/BaseSpace/BaseSpace';
import { EditOutlined, EyeOutlined, LockOutlined, UnlockOutlined, UploadOutlined } from '@ant-design/icons';
import { Button, Col, Form, Image, Input, message, Modal, Row, Select, Upload } from 'antd';
import { Book, getBooks, getBookById } from '@app/api/book.api';
import { Loading } from '@app/components/common/Loading/Loading';
import { InputSearch } from '@app/components/header/components/HeaderSearch/HeaderSearch.styles';
import { Biblio, getBiblio, getBiblioById, putLock, putUnlock, putUpdate } from '@app/api/themsach.api';
import { Label } from '@app/components/nft-dashboard/Balance/components/TopUpBalanceForm/TopUpBalanceForm.styles';
import { UploadFile } from 'antd/es/upload/interface';
import TextArea from 'antd/lib/input/TextArea';
import { Category, getCategories } from '@app/api/category.api';

export const ThemMoiSachTable: React.FC = () => {
  const [tableData, setTableData] = useState<{ data: Biblio[]; loading: boolean }>({
    data: [],
    loading: false,
  });
  const [Categorys, setCategorys] = useState<{ data: Category[]; loading: boolean }>({
    data: [],
    loading: false,
  });
  const [isDetailModalVisible, setIsDetailModalVisible] = useState(false);
  const [isUpdateModalVisible, setIsUpdateModalVisible] = useState(false);
  const [selectedBiblio, setselectedBiblio] = useState<Biblio | null>(null);
  const { t } = useTranslation();
  const { isMounted } = useMounted();
  const [form] = Form.useForm();
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [categoryOptions, setCategoryOptions] = useState<string[]>([]);
  const [selectedCategoryOptions, setSelectedCategoryOptions] = useState<string>('Default');
  const [selectedCategory, setSelectedCategory] = useState<string>('Default');

  const fetch = useCallback(() => {
    setTableData((tableData) => ({ ...tableData, loading: true }));
    getBiblio({ current: 1, pageSize: 10 }).then((res) => {
      if (isMounted.current) {
        const books = res.data;

        const categories = Array.from(new Set(books.map((book) => book.Category)));

        setCategoryOptions(categories);
        setTableData({ data: books, loading: false });
      }
    });

    getCategories({ current: 1, pageSize: 10 }).then((res) => {
      if (isMounted.current) {
        setCategorys({ data: res.data, loading: false });
      }
    });
  }, [isMounted]);

  useEffect(() => {
    fetch();
  }, [fetch]);

  const handleTableChange = () => {
    fetch();
  };

  const handleDeleteRow = (rowId: any) => {
    setTableData((prevTableData) => ({
      ...prevTableData,
      data: prevTableData.data.filter((item) => item.BookId !== rowId),
    }));
  };

  const handleUpdate = async () => {
    try {
      const updatedData = await form.validateFields();
      if (selectedBiblio) {
        const biblioData = new FormData();

        biblioData.append('BookId', updatedData.BookId);
        biblioData.append('ISBN', updatedData.ISBN);
        biblioData.append('BookName', updatedData.BookName);
        biblioData.append('AuthorName', updatedData.Authors);
        biblioData.append('PublisherName', updatedData.PublisherName);
        biblioData.append('PublishYear', updatedData.PublishYear);
        biblioData.append('CategoryId', updatedData.CategoryId);
        biblioData.append('Notes', updatedData.Notes);

        if (fileList.length > 0) {
          const thumbnailFile = fileList[0].originFileObj as File;
          biblioData.append('Thumbnail', thumbnailFile);
        } else {
          biblioData.append('Thumbnail', selectedBiblio?.Thumbnail || '');
        }

        console.log('Form data:', updatedData, biblioData);
        const response = await putUpdate(biblioData);
        setIsUpdateModalVisible(false);
        form.resetFields();
        setFileList([]);
        console.log('API response:', response);
        message.success('Sách đã được cập nhật thành công.');
        window.location.reload();
      }
    } catch (error) {
      message.error('Lỗi khi cập nhật thông tin sách');
      console.error('Error updating member:', error);
    }
  };

  const handleCancelUpdate = () => {
    setIsUpdateModalVisible(false);
    setselectedBiblio(null);
  };

  const showModalUpdate = async (BookId: any) => {
    setIsUpdateModalVisible(true);
    setTableData((prevState) => ({ ...prevState, loading: true }));

    try {
      const bookid = await getBiblioById(BookId);
      setselectedBiblio(bookid);
      form.setFieldsValue(bookid);
    } catch (error) {
      console.error('Error fetching loan data:', error);
    } finally {
      setTableData((prevState) => ({ ...prevState, loading: false }));
    }
  };

  const handleLock = async (BookId: number, BookName: string) => {
    try {
      await putLock(BookId);
      message.success(`Sách ${BookName} đã được khoá!`);
      setTableData((prevTableData) => ({
        ...prevTableData,
        data: prevTableData.data.map((book) => (book.BookId === BookId ? { ...book, IsLocked: 1 } : book)),
      }));
    } catch (error) {
      console.error('Error locking member:', error);
    }
  };

  const handleUnlock = async (BookId: number, BookName: string) => {
    try {
      await putUnlock(BookId);
      message.success(`Sách ${BookName} đã được mở khóa!`);
      setTableData((prevTableData) => ({
        ...prevTableData,
        data: prevTableData.data.map((book) => (book.BookId === BookId ? { ...book, IsLocked: 0 } : book)),
      }));
    } catch (error) {
      console.error('Error unlocking member:', error);
    }
  };

  const showModal = async (BookId: any) => {
    setIsDetailModalVisible(true);
    setTableData((prevState) => ({ ...prevState, loading: true }));

    try {
      const book = await getBiblioById(BookId);
      setselectedBiblio(book);
    } catch (error) {
      console.error('Error fetching book data:', error);
    } finally {
      setTableData((prevState) => ({ ...prevState, loading: false }));
    }
  };

  const handleFileChange = ({ fileList }: { fileList: UploadFile[] }) => {
    setFileList(fileList);
  };

  const modalUpdate = (
    <Modal
      title={`Cập nhật thông tin: ${selectedBiblio?.BookName}`}
      open={isUpdateModalVisible}
      onCancel={handleCancelUpdate}
      footer={null}
      width={800}
      style={{ top: 20 }}
    >
      {selectedBiblio ? (
        <Form layout="vertical" form={form} initialValues={selectedBiblio}>
          <Row gutter={16}>
            <Col span={12} style={{ display: 'flex', justifyContent: 'center' }}>
              <Image src={selectedBiblio.Thumbnail} width={300} alt="Không có ảnh" />
            </Col>
            <Col span={12}>
              <Form.Item label="Ảnh bìa sách" name="Thumbnail">
                <Upload
                  listType="picture"
                  maxCount={1}
                  fileList={fileList}
                  beforeUpload={() => false}
                  onChange={handleFileChange}
                >
                  <Button icon={<UploadOutlined />}>Chọn tệp</Button>
                </Upload>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="ID Sách" name="BookId">
                <Input disabled />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="ISBN" name="ISBN" rules={[{ required: true, message: 'Vui lòng nhập ISBN!' }]}>
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Tên sách"
                name="BookName"
                rules={[{ required: true, message: 'Vui lòng nhập tên sách!' }]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Tác giả" name="Authors">
                <Input value={selectedBiblio.Authors.join(', ')} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Nhà xuất bản"
                name="PublisherName"
                rules={[{ required: true, message: 'Vui lòng nhập nhà xuất bản!' }]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Năm xuất bản"
                name="PublishYear"
                rules={[{ required: true, message: 'Vui lòng nhập năm xuất bản!' }]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="ID Danh mục"
                name="CategoryId"
                initialValue={selectedBiblio.CategoryId}
                rules={[{ required: true, message: 'Vui lòng nhập ID Danh mục!' }]}
              >
                <Select
                  value={selectedCategory}
                  defaultValue={selectedCategory}
                  onChange={(e) => setSelectedCategory(e)}
                >
                  <Select.Option value="Default">Danh mục</Select.Option>
                  {Categorys.data.map((category) => (
                    <Select.Option key={category.CategoryCode} value={category.CategoryId}>
                      {category.CategoryName}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Ghi chú" name="Notes">
                <TextArea autoSize={{ minRows: 1 }} />
              </Form.Item>
            </Col>
          </Row>

          <Form.Item style={{ marginTop: '20px' }}>
            <BaseButton style={{ width: '100%' }} key="update" type="primary" onClick={handleUpdate}>
              Cập nhật
            </BaseButton>
          </Form.Item>
        </Form>
      ) : (
        <Loading />
      )}
    </Modal>
  );

  const handleCancel = () => {
    setIsDetailModalVisible(false);
    setselectedBiblio(null);
  };

  const handleSubmit = (values: unknown) => {
    console.log('Received values:', values);
    setIsDetailModalVisible(false);
  };

  const formatDateToVN = (dateString: string | number | Date) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('vi-VN');
  };

  const modalChitiet = (
    <Modal
      title={`${selectedBiblio?.BookName}`}
      open={isDetailModalVisible}
      onCancel={handleCancel}
      footer={null}
      style={{ top: 20 }}
      width={800}
    >
      {selectedBiblio ? (
        <Form layout="horizontal" onFinish={handleSubmit}>
          <Row gutter={16}>
            <Col span={24} style={{ display: 'flex', justifyContent: 'center' }}>
              <Image src={selectedBiblio.Thumbnail} width={300} alt="Không có ảnh" />
            </Col>
            <Col span={12}>
              <Form.Item label="Tên sách" name="BookName">
                <Label style={{ color: 'black', fontWeight: 'bold' }}>{selectedBiblio.BookName}</Label>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Tác giả" name="Authors">
                <Label style={{ color: 'black', fontWeight: 'bold' }}>{selectedBiblio.Authors.join(', ')}</Label>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Năm xuất bản" name="PublishYear">
                <Label style={{ color: 'black', fontWeight: 'bold' }}>{selectedBiblio.PublishYear}</Label>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Danh mục" name="Category">
                <Label style={{ color: 'black', fontWeight: 'bold' }}>{selectedBiblio.Category}</Label>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Có sẵn" name="Available">
                <Label style={{ color: 'black', fontWeight: 'bold' }}>{selectedBiblio.Available}</Label>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Cho mượn" name="OnLoan">
                <Label style={{ color: 'black', fontWeight: 'bold' }}>{selectedBiblio.OnLoan}</Label>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Ngày tạo" name="CreatedAt">
                <Label style={{ color: 'black', fontWeight: 'bold' }}>{formatDateToVN(selectedBiblio.CreatedAt)}</Label>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Nhà xuất bản" name="Publisher">
                <Label style={{ color: 'black', fontWeight: 'bold' }}>{selectedBiblio.PublisherName}</Label>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Barcode" name="Barcode">
                <Label style={{ color: 'black', fontWeight: 'bold' }}>
                  {selectedBiblio.Items && selectedBiblio.Items.length > 0
                    ? selectedBiblio.Items[0].Barcode
                    : 'Không có dữ liệu'}
                </Label>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Vị trí" name="Location">
                <Label style={{ color: 'black', fontWeight: 'bold' }}>
                  {selectedBiblio.Items && selectedBiblio.Items.length > 0
                    ? selectedBiblio.Items[0].Location
                    : 'Không có dữ liệu'}
                </Label>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      ) : (
        <Loading />
      )}
    </Modal>
  );

  const columns: ColumnsType<Biblio> = [
    {
      title: t('common.IDsach'),
      dataIndex: 'BookId',
      width: '10%',
      align: 'center',
    },
    {
      title: t('common.tensach'),
      dataIndex: 'BookName',
    },
    {
      title: t('common.tacgia'),
      dataIndex: 'Authors',
    },
    {
      title: t('common.namxb'),
      dataIndex: 'PublishYear',
      width: '10%',
      align: 'center',
    },
    {
      title: t('Danh mục'),
      dataIndex: 'Category',
      width: '10%',
    },
    {
      title: t('tables.actions'),
      dataIndex: 'actions',
      width: '10%',
      align: 'center',
      render: (text, record) => {
        return (
          <BaseSpace>
            <BaseButton type="link" onClick={() => showModal(record.BookId)}>
              <EyeOutlined />
            </BaseButton>
            <BaseButton type="link" onClick={() => showModalUpdate(record.BookId)} style={{ color: 'green' }}>
              <EditOutlined />
            </BaseButton>
            {record.IsLocked === 0 ? (
              <BaseButton type="link" danger onClick={() => handleLock(record.BookId, record.BookName)}>
                <LockOutlined />
              </BaseButton>
            ) : (
              <BaseButton type="link" danger onClick={() => handleUnlock(record.BookId, record.BookName)}>
                <UnlockOutlined />
              </BaseButton>
            )}
          </BaseSpace>
        );
      },
    },
  ];

  const filteredData = tableData.data.filter((item) => {
    const matchesSearchTerm =
      String(item.BookId).toLowerCase().includes(searchTerm.toLowerCase()) ||
      String(item.BookName).toLowerCase().includes(searchTerm.toLowerCase());

    const matchesCategory = selectedCategoryOptions === 'Default' || item.Category === selectedCategoryOptions;

    return matchesSearchTerm && matchesCategory;
  });

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
  };

  const handleCategoryChange = (value: string) => {
    setSelectedCategory(value);
  };

  return (
    <div style={{ background: 'white', borderRadius: '10px', margin: '20px', padding: '0 10px' }}>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          height: '80px',
        }}
      >
        <div
          style={{
            marginRight: 'auto',
          }}
        >
          <Select
            defaultValue="Danh mục"
            style={{ width: 170, borderRadius: '8px' }}
            bordered={true}
            dropdownStyle={{ borderRadius: '8px' }}
            onChange={(e) => setSelectedCategoryOptions(e)}
          >
            <Select.Option value="Default">Tất cả</Select.Option>
            {categoryOptions.map((category, index) => (
              <Select.Option key={category + index} value={category}>
                {category}
              </Select.Option>
            ))}
          </Select>
        </div>
        <div style={{ display: 'flex', marginLeft: 'auto' }}>
          <InputSearch
            style={{ width: '350px', marginRight: '10px' }}
            placeholder={t('Tìm kiếm theo ID, Tên sách, Vị trí')}
            onChange={handleSearch}
          />
        </div>
      </div>
      <BaseTable
        columns={columns}
        dataSource={filteredData}
        pagination={{ pageSize: 10 }}
        loading={tableData.loading}
        onChange={handleTableChange}
        scroll={{ x: 800 }}
        bordered
      />
      {modalChitiet}
      {modalUpdate}
    </div>
  );
};
