import { APIResponse, httpApi } from './http.api';
import { Pagination } from './table.api';

export interface Location {
  LocationId: string;
  LocationName: string;
  CategoryName: string;
  CategoryId: number;
  CategoryCode: string;
  BookCount: number;
  UpdatedAt: string;
  UpdatedBy: string;
  StatusName: string;
}

export interface locationResponse {
  data: Location[];
  pagination: Pagination;
}

export const getlocations = async (pagination: Pagination) => {
  const location: Location[] = await httpApi.get<APIResponse>('/location').then((res) => res.data.data);
  return {
    data: location,
    pagination: { ...pagination, total: location.length },
  };
};
