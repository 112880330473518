import React from 'react';
import { useTranslation } from 'react-i18next';
import { LoginForm } from '@app/components/auth/LoginForm/LoginForm';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import { useAppSelector } from '@app/hooks/reduxHooks';
import { Navigate } from 'react-router-dom';

const LoginPage: React.FC = () => {
  const { t } = useTranslation();
  const token = useAppSelector((state) => state.auth.token);
  return !token ? (
    <>
      <PageTitle>{t('common.login')}</PageTitle>
      <LoginForm />
    </>
  ) : (
    <Navigate to="/" replace />
  );
};

export default LoginPage;
