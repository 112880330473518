import axios from 'axios';
import { AxiosError } from 'axios';
import { ApiError } from '@app/api/ApiError';
import { clearAuth, persistAccessToken, readAccessToken, readRefreshToken } from '@app/services/localStorage.service';
import { refresh } from './auth.api';
import { UNSAFE_NavigationContext } from 'react-router-dom';
import { message } from 'antd';
import { AnyAsyncThunk } from '@reduxjs/toolkit/dist/matchers';

let isRefreshToken = false;
let requestsToRefresh: any[] = [];

export const httpApi = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});

httpApi.interceptors.request.use(
  (config) => {
    config.headers = { ...config.headers, Authorization: `Bearer ${readAccessToken()}` };

    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

httpApi.interceptors.response.use(
  (response) => response,
  (error: AxiosError<ApiErrorData>) => {
    const { response, config } = error;
    const status = response?.data.Status;

    if (status === 401) {
      const refreshToken = readRefreshToken();
      if (!refreshToken) {
        return Promise.reject(error);
      }

      if (!isRefreshToken) {
        isRefreshToken = true;
        refresh(refreshToken)
          .then(({ AccessToken }) => {
            requestsToRefresh.forEach((callback) => {
              callback(AccessToken);
            });
            persistAccessToken(AccessToken);
          })
          .catch((error) => {
            clearAuth();
            requestsToRefresh.forEach((cb) => cb(null));
          })
          .finally(() => {
            isRefreshToken = false;
            requestsToRefresh = [];
          });
      }

      return new Promise((resolve, reject) => {
        requestsToRefresh.push((token: string) => {
          if (token) {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            config.headers!.Authorization = `Bearer ${token}`;
            resolve(axios(config));
          }

          reject(error);
        });
      });
    }

    if (error.response && error.response.status === 429) {
      message.error('Lỗi kết nối. Vui lòng tải lại trang hoặc ấn phím F5.');
    }

    if (error.response && error.response.status === 422) {
      // Lỗi validate
      const validationErrors: { [key: string]: string | any } = error.response.data;
      console.log(validationErrors);
      for (const key in validationErrors) {
        let finalMessage = 'Lỗi ';
        for (const msg of validationErrors[key]) {
          finalMessage += msg;
        }
        message.error(finalMessage);
      }
    } else {
      message.error(error.response?.data.message);
    }

    // throw new ApiError<ApiErrorData>(error.response?.data.message || error.message, error.response?.data);
    console.log(error.response?.data.message || error.message, error.response?.data);

    return Promise.reject(error);
  },
);

export interface ApiErrorData {
  IsSuccess: boolean;
  Status: number;
  message: string;
}

export interface APIResponse {
  IsSuccess: boolean;
  Status: number;
  data: any[] | any;
}
