/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useState } from 'react';
import { useResponsive } from '@app/hooks/useResponsive';
import { CloseOutlined } from '@ant-design/icons';
import { Button, Modal, Form, Input, Row, Col, Select, message } from 'antd';
import { useMounted } from '@app/hooks/useMounted';
import { getlocations, Location } from '@app/api/location.api';
import { ItemInput, storeBookItem } from '@app/api/book.api';
import { Biblio, getBiblioByKeyword } from '@app/api/themsach.api';

import { useDebounce } from 'use-debounce';
import { Label } from '@app/components/nft-dashboard/Balance/components/TopUpBalanceForm/TopUpBalanceForm.styles';

type Props = {
  isModalVisible: boolean;
  toggleShowNhapSach: () => void;
};

function NhapSach(props: Props) {
  const [isClicked, setIsClicked] = useState(false);
  const [Locations, setLocations] = useState<{ data: Location[]; loading: boolean }>({
    data: [],
    loading: false,
  });
  const { isDesktop } = useResponsive();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [bookList, setBookList] = useState([{ id: 1, barcode: '' }]);
  const { isMounted } = useMounted();
  const [form] = Form.useForm();
  const [BiblioResult, setBiblioResult] = useState<Biblio[]>([]);
  const [selectedBiblio, setSelectedBiblio] = useState<Biblio | null>(null);
  const [selectedLocation, setSelectedLocation] = useState<string>('Default');
  const [keyword, setKeyword] = useState<string>('');
  const [debouncedKeyword] = useDebounce(keyword, 1000);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    form.resetFields();
    setBookList([{ id: 1, barcode: '' }]);
  };

  const fetch = useCallback(() => {
    setLocations((tableData) => ({ ...tableData, loading: true }));
    getlocations({ current: 1, pageSize: 10 }).then((res) => {
      if (isMounted.current) {
        setLocations({ data: res.data, loading: false });
      }
    });
  }, [isMounted]);

  useEffect(() => {
    fetch();
  }, [fetch]);

  useEffect(() => {
    if (debouncedKeyword) {
      fetchBooks(debouncedKeyword);
    }
  }, [debouncedKeyword]);

  const onFinish = async (values: any) => {
    try {
      setIsClicked(true);
      if (!selectedBiblio) return;

      const data: ItemInput = {
        Items: [
          {
            Barcode: bookList.map((book) => book.barcode),
            BookId: selectedBiblio.BookId,
            LocationId: values.LocationId,
          },
        ],
      };

      console.log('Received values from form: ', data);

      const response = await storeBookItem(data);
      console.log('Response:', response);

      form.resetFields();
      setBookList([{ id: 1, barcode: '' }]);
      props.toggleShowNhapSach();
      message.success('Danh mục sách đã được thêm thành công.');
      setIsClicked(false);
      window.location.reload();
    } catch (error: any) {
      setIsClicked(false);
    }
  };

  const addBookRow = () => {
    setBookList([...bookList, { id: bookList.length + 1, barcode: '' }]);
  };

  const removeBookRow = (id: number) => {
    setBookList(bookList.filter((book) => book.id !== id));
  };

  const fetchBooks = async (Keyword: string) => {
    console.log(Keyword);
    try {
      const data: Biblio[] = await getBiblioByKeyword(Keyword);
      setBiblioResult(data);
    } catch (error) {
      console.error('Error fetching loan data:', error);
    }
  };

  const handleLocationChange = (value: string) => {
    setSelectedLocation(value);
  };

  const totalBarcodes = bookList.length;

  return (
    <Modal
      title="Nhập Sách"
      open={props.isModalVisible}
      onCancel={() => {
        form.resetFields();
        props.toggleShowNhapSach();
      }}
      footer={
        <div style={{ textAlign: 'center' }}>
          <Button
            type="primary"
            htmlType="submit"
            onClick={() => {
              form
                .validateFields()
                .then((values) => {
                  onFinish(values);
                })
                .catch((info) => {
                  console.log('Validate Failed:', info);
                });
            }}
            size="middle"
            disabled={isClicked}
            loading={isClicked}
          >
            Xác Nhận
          </Button>
        </div>
      }
      style={{ top: 20 }}
      width={800}
    >
      <Form layout="vertical" form={form} onFinish={onFinish}>
        <Row gutter={16}>
          <Col span={20}>
            <Form.Item
              label="Tên sách"
              name={`BookName`}
              rules={[{ required: true, message: 'Vui lý nhập tên sách!' }]}
            >
              <Select
                showSearch
                onSearch={(value) => setKeyword(value)}
                onChange={(value) => {
                  console.log(value);
                  setSelectedBiblio(JSON.parse(value));
                }}
                placeholder="Nhập tên sách"
                filterOption={false}
                notFoundContent={null}
              >
                {BiblioResult.map((biblio) => (
                  <Select.Option value={JSON.stringify(biblio)} key={biblio.BookId}>
                    {biblio.BookName}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item label="Vị trí" name="LocationId" rules={[{ required: true, message: 'Vui lòng chọn vị trí!' }]}>
              <Select value={selectedLocation} defaultValue={selectedLocation} onChange={handleLocationChange}>
                <Select.Option value="Default">Vị trí</Select.Option>
                {Locations.data.map((location) => (
                  <Select.Option key={location.LocationName} value={location.LocationId}>
                    {location.LocationName}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="Năm xuất bản:">
              <Label>{selectedBiblio?.PublishYear ?? 'N/A'}</Label>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="Nhà xuất bản:">
              <Label>{selectedBiblio?.PublisherName ?? 'N/A'}</Label>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="Tác giả:">
              <Label>{selectedBiblio?.Authors?.join(' - ') ?? 'N/A'}</Label>
            </Form.Item>
          </Col>
          <Col span={8} style={{ marginBottom: '10px' }}>
            <Label>Tổng số sách đã nhập: {totalBarcodes}</Label>
          </Col>
          <Col span={12}>
            <a type="link" onClick={addBookRow}>
              Thêm Sách
            </a>
          </Col>
        </Row>

        {bookList.map((book, index) => (
          <div key={book.id} style={{ marginBottom: '10px' }}>
            <Row gutter={16} align={'bottom'}>
              <Col span={24}>
                <Form.Item
                  label={`Sách thứ ${index + 1}:`}
                  name={`barcode-${book.id}`}
                  rules={[{ required: true, message: 'Vui lòng nhập Barcode!' }]}
                >
                  <Input
                    style={{ width: '100%' }}
                    value={book.barcode}
                    placeholder="Nhập barcode"
                    suffix={
                      <CloseOutlined type="text" style={{ color: 'red' }} onClick={() => removeBookRow(book.id)} />
                    }
                    onChange={(e) => {
                      const newList = [...bookList];
                      newList[index].barcode = e.target.value;
                      setBookList(newList);
                    }}
                  />
                </Form.Item>
              </Col>
            </Row>
          </div>
        ))}

        {/* <Form.Item style={{ marginTop: '20px' }}>
          <Row gutter={16}>
            <Col span={12}>
              <Button
                style={{ width: '100%' }}
                type="primary"
                htmlType="submit"
                size="middle"
                disabled={isClicked}
                loading={isClicked}
              >
                Xác Nhận
              </Button>
            </Col>
          </Row>
        </Form.Item> */}
      </Form>
    </Modal>
  );
}

export default NhapSach;
