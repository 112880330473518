/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState, useCallback } from 'react';
import { BaseTable } from '@app/components/common/BaseTable/BaseTable';
import { ColumnsType } from 'antd/es/table';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { useTranslation } from 'react-i18next';
import { useMounted } from '@app/hooks/useMounted';
import { BaseSpace } from '@app/components/common/BaseSpace/BaseSpace';
import { EditOutlined, EyeOutlined, LockOutlined, UnlockOutlined } from '@ant-design/icons';
import { Button, Col, Form, Input, message, Modal, Row, Select, Tag, Tooltip } from 'antd';
import { InputSearch } from '@app/components/header/components/HeaderSearch/HeaderSearch.styles';
import { getMember, getMemberById, Member, putLock, putUnlock, putUpdate } from '@app/api/thanhvien.api';
import { Loading } from '@app/components/common/Loading/Loading';
import { Label } from '@app/components/nft-dashboard/Balance/components/TopUpBalanceForm/TopUpBalanceForm.styles';
import { ColumnFilterItem } from 'antd/lib/table/interface';

function extractKey(input: any) {
  let key = '';
  let temp = '';

  for (let i = 0; i < input.length; i++) {
    if (!isNaN(input[i]) && input[i] !== ' ') {
      temp += input[i];
    } else if (isNaN(input[i])) {
      if (temp !== '') {
        key = temp;
        temp = '';
      }
    }
  }

  return key;
}

export const ThanhVienTable: React.FC = () => {
  const [tableData, setTableData] = useState<{ data: Member[]; loading: boolean }>({
    data: [],
    loading: false,
  });
  const [isDetailModalVisible, setIsDetailModalVisible] = useState(false);
  const [isUpdateModalVisible, setIsUpdateModalVisible] = useState(false);
  const [selectedMember, setSelectedMember] = useState<Member | null>(null);
  const { t } = useTranslation();
  const { isMounted } = useMounted();
  const [form] = Form.useForm();
  const [showAll, setShowAll] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedType, setSelectedType] = useState<string>('all');
  const [classes, setClasses] = useState<ColumnFilterItem[]>([]);

  const fetch = useCallback(() => {
    setTableData((tableData) => ({ ...tableData, loading: true }));
    getMember({ current: 1, pageSize: 10 }).then((res) => {
      if (isMounted.current) {
        setTableData({ data: res.data, loading: false });
        const uniqueClasses = new Set();
        res.data.map((data) => {
          if (!uniqueClasses.has(data.Class)) {
            uniqueClasses.add(extractKey(data.Class));
          }
        });
        const classesFilter: ColumnFilterItem[] = [];
        const _ = Array.from(uniqueClasses);
        for (let i = 0; i < _.length; i++) {
          const value: any = _[i];
          classesFilter.push({
            text: 'Khoá ' + value,
            value: value,
          });
        }
        setClasses(classesFilter);
      }
    });
  }, [isMounted]);

  useEffect(() => {
    fetch();
  }, [fetch]);

  const handleTableChange = () => {
    fetch();
  };

  const handleDeleteRow = (rowId: any) => {
    setTableData((prevTableData) => ({
      ...prevTableData,
      data: prevTableData.data.filter((item) => item.MemberId !== rowId),
    }));
  };

  const handleUpdateMember = async () => {
    try {
      const updatedData = await form.validateFields();
      if (selectedMember) {
        const transformedValues = {
          OldMemberId: selectedMember.MemberId,
          NewMemberId: updatedData.MemberId || selectedMember.MemberId,
          MemberName: updatedData.MemberName,
          Class: updatedData.Class,
          Gender: updatedData.Gender,
          BirthDate: updatedData.BirthDate,
          MemberAddress: updatedData.MemberAddress,
          MemberTypeId: updatedData.MemberTypeId,
          MemberPhone: updatedData.PhoneNumber,
          MemberEmail: updatedData.Email,
        };

        console.log('Received values from form: ', transformedValues);
        const response = await putUpdate(transformedValues);
        setIsUpdateModalVisible(false);
        form.resetFields();
        console.log('API response:', response);
        message.success('Thành viên đã được cập nhật thành công.');
        window.location.reload();
      }
    } catch (error) {
      message.error('Lỗi khi cập nhật thông tin thành viên');
      console.error('Error updating member:', error);
    }
  };

  const handleCancelUpdate = () => {
    setIsUpdateModalVisible(false);
    setSelectedMember(null);
  };

  const showModalUpdate = async (MemberId: string) => {
    setIsUpdateModalVisible(true);
    setTableData((prevState) => ({ ...prevState, loading: true }));

    try {
      const Memberid = await getMemberById(MemberId);
      setSelectedMember(Memberid);
      form.setFieldsValue(Memberid);
    } catch (error) {
      console.error('Error fetching loan data:', error);
    } finally {
      setTableData((prevState) => ({ ...prevState, loading: false }));
    }
  };

  const handleDateChange = (value: string) => {
    if (value.length > 10) return;
    const numbers = value.replace(/[^0-9]/g, '');
    let formattedValue = '';
    if (numbers.length >= 2) {
      formattedValue += numbers.slice(0, 2);
      if (numbers.length > 2) {
        formattedValue += '/' + numbers.slice(2, 4);
        if (numbers.length > 4) {
          formattedValue += '/' + numbers.slice(4, 8);
        }
      }
    } else {
      formattedValue = numbers;
    }
    form.setFieldsValue({ BirthDate: formattedValue });
  };

  const modalUpdate = (
    <Modal
      title={`Cập nhật thông tin: ${selectedMember?.MemberName}`}
      open={isUpdateModalVisible}
      onCancel={handleCancelUpdate}
      footer={
        <div style={{ textAlign: 'center' }}>
          <Button type="primary" htmlType="submit" key="update" onClick={handleUpdateMember} size="middle">
            Xác Nhận
          </Button>
        </div>
      }
      width={800}
      style={{ top: 20 }}
    >
      {selectedMember ? (
        <Form layout="vertical" form={form} initialValues={selectedMember}>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item label="ID Thành viên" name="MemberId">
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Họ và Tên" name="MemberName">
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Lớp" name="Class">
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Giới tính" name="Gender">
                <Select value={selectedMember.Gender}>
                  <Select.Option value={0}>Nam</Select.Option>
                  <Select.Option value={1}>Nữ</Select.Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Ngày sinh" name="BirthDate">
                <Input onChange={(e) => handleDateChange(e.target.value)} maxLength={10} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Địa chỉ"
                name="MemberAddress"
                rules={[{ required: true, message: 'Vui lòng nhập địa chỉ' }]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="ID Phân loại thành viên" name="MemberTypeId">
                <Select value={selectedMember.MemberTypeId}>
                  <Select.Option value={1}>Học sinh / Sinh viên</Select.Option>
                  <Select.Option value={2}>Giảng viên</Select.Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Số điện thoại" name="PhoneNumber">
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Email" name="Email">
                <Input />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      ) : (
        <Loading />
      )}
    </Modal>
  );

  const handleLockMember = async (MemberId: string, MemberName: string) => {
    try {
      await putLock(MemberId);
      message.success(`Thành viên ${MemberName} đã được khoá!`);
      setTableData((prevTableData) => ({
        ...prevTableData,
        data: prevTableData.data.map((member) => (member.MemberId === MemberId ? { ...member, IsLocked: 1 } : member)),
      }));
    } catch (error) {
      console.error('Error locking member:', error);
    }
  };

  const handleUnlockMember = async (MemberId: string, MemberName: string) => {
    try {
      await putUnlock(MemberId);
      message.success(`Thành viên ${MemberName} đã được mở khoá!`);
      setTableData((prevTableData) => ({
        ...prevTableData,
        data: prevTableData.data.map((member) => (member.MemberId === MemberId ? { ...member, IsLocked: 0 } : member)),
      }));
    } catch (error) {
      console.error('Error unlocking member:', error);
    }
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
  };

  const filteredData = tableData.data
    .filter((item) => (showAll ? true : selectedType === 'all' || item.MemberTypeName === selectedType))
    .filter(
      (item) =>
        String(item.MemberId).toLowerCase().includes(searchTerm.toLowerCase()) ||
        String(item.MemberName).toLowerCase().includes(searchTerm.toLowerCase()),
    );

  const columns: ColumnsType<Member> = [
    {
      title: t('common.ID'),
      dataIndex: 'MemberId',
      align: 'center',
      width: '10%',
    },
    {
      title: t('common.name'),
      dataIndex: 'MemberName',
    },
    {
      title: t('common.sdt'),
      dataIndex: 'PhoneNumber',
      align: 'right',
      width: '10%',
    },
    {
      title: t('common.solanmuon'),
      dataIndex: 'BorrowedBooks',
      align: 'right',
      width: '10%',
    },
    {
      title: 'Khoá',
      dataIndex: 'Class',
      align: 'right',
      width: '10%',
      render: (value) => {
        return <Tag color="red">{value}</Tag>;
      },
      filterSearch: true,
      filters: classes,
      onFilter: (value, record) => record.Class.includes(value as string),
    },
    // {
    //   title: t('common.tinhtrang'),
    //   dataIndex: 'BorrowingBooks',
    //   align: 'center',
    //   width: '15%',
    //   render: (BorrowingBooks) => (
    //     <span
    //       style={{
    //         backgroundColor: BorrowingBooks == 0 ? '#2fb350' : '#FF0000',
    //         color: 'white',
    //         padding: '5px 10px',
    //         borderRadius: '10px',
    //       }}
    //     >
    //       {BorrowingBooks == 0 ? 'Không mượn sách' : 'Đang mượn sách'}
    //     </span>
    //   ),
    // },
    {
      title: t('common.dangmuon'),
      dataIndex: 'BorrowingBooks',
      align: 'right',
      width: '10%',
    },
    {
      title: t('common.phanloai'),
      dataIndex: 'MemberTypeName',
      width: '10%',
      align: 'center',
    },
    {
      title: t('tables.actions'),
      dataIndex: 'actions',
      width: '10%',
      align: 'center',
      render: (_, record) => (
        <BaseSpace>
          <Tooltip title="Xem chi tiết">
            <BaseButton type="link" onClick={() => showModal(record.MemberId)}>
              <EyeOutlined />
            </BaseButton>
          </Tooltip>
          <Tooltip title="Chỉnh sửa thông tin">
            <BaseButton type="link" onClick={() => showModalUpdate(record.MemberId)} style={{ color: 'green' }}>
              <EditOutlined />
            </BaseButton>
          </Tooltip>
          {record.IsLocked === 0 ? (
            <Tooltip title="Khóa thành viên">
              <BaseButton type="link" danger onClick={() => handleLockMember(record.MemberId, record.MemberName)}>
                <UnlockOutlined />
              </BaseButton>
            </Tooltip>
          ) : (
            <Tooltip title="Mở khóa thành viên">
              <BaseButton type="link" danger onClick={() => handleUnlockMember(record.MemberId, record.MemberName)}>
                <LockOutlined />
              </BaseButton>
            </Tooltip>
          )}
        </BaseSpace>
      ),
    },
  ];

  const modalColumns: ColumnsType<Member['LoanDetails'][number]> = [
    {
      title: '#',
      render: (text, record, index) => index + 1,
      align: 'center',
      width: '5%',
    },
    {
      title: 'ID Sách',
      dataIndex: 'BookId',
      align: 'right',
    },
    {
      title: 'Barcode',
      dataIndex: 'Barcode',
      align: 'left',
    },
    {
      title: 'Thời gian mượn',
      dataIndex: 'LoanDate',
      align: 'center',
      render: (record) => new Date(record).toLocaleDateString('vi-VN'),
    },
    {
      title: 'Thời gian trả',
      dataIndex: 'RetunDate',
      align: 'center',
      render: (record) => (record ? new Date(record).toLocaleDateString('vi-VN') : 'Chưa trả sách'),
    },
  ];

  const showModal = async (MemberId: string) => {
    setIsDetailModalVisible(true);
    setTableData((prevState) => ({ ...prevState, loading: true }));

    try {
      const Memberid = await getMemberById(MemberId);
      setSelectedMember(Memberid);
    } catch (error) {
      console.error('Error fetching loan data:', error);
    } finally {
      setTableData((prevState) => ({ ...prevState, loading: false }));
    }
  };

  const handleCancel = () => {
    setIsDetailModalVisible(false);
    setSelectedMember(null);
  };

  const formatDateToVN = (dateString: string | number | Date) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('vi-VN');
  };

  const modalChitiet = (
    <Modal
      title={`${selectedMember?.MemberName}`}
      open={isDetailModalVisible}
      onCancel={handleCancel}
      footer={null}
      width={800}
      style={{ top: 20 }}
    >
      {selectedMember ? (
        <Form layout="horizontal">
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item label="Họ và Tên" name="MemberName">
                <Label style={{ color: 'black', fontWeight: 'bold' }}>{selectedMember.MemberName}</Label>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Mã Thành viên" name="MemberId">
                <Label style={{ color: 'black', fontWeight: 'bold' }}>{selectedMember.MemberId}</Label>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Số điện thoại" name="PhoneNumber">
                <Label style={{ color: 'black', fontWeight: 'bold' }}>{selectedMember.PhoneNumber}</Label>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Email" name="Email">
                <Label style={{ color: 'black', fontWeight: 'bold' }}>{selectedMember.Email}</Label>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Phân loại" name="MemberTypeName">
                <Label style={{ color: 'black', fontWeight: 'bold' }}>{selectedMember.MemberTypeName}</Label>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Ngày đăng ký" name="CreatedAt">
                <Label style={{ color: 'black', fontWeight: 'bold' }}>{formatDateToVN(selectedMember.CreatedAt)}</Label>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Số sách đã mượn" name="BorrowedBooks">
                <Label style={{ color: 'black', fontWeight: 'bold' }}>{selectedMember.BorrowedBooks}</Label>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Đang mượn" name="BorrowingBooks">
                <Label style={{ color: 'black', fontWeight: 'bold' }}>{selectedMember.BorrowingBooks}</Label>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label={`Sách ${selectedMember?.MemberName} đã mượn:`} style={{ marginBottom: '20px' }} />
              <BaseTable columns={modalColumns} dataSource={selectedMember?.LoanDetails || []} pagination={false} />
            </Col>
          </Row>
        </Form>
      ) : (
        <Loading />
      )}
    </Modal>
  );

  const handleShowAll = () => {
    setShowAll(true);
    setSelectedType('all');
  };

  const handleShowType = (value: string) => {
    setShowAll(false);
    setSelectedType(value);
  };

  return (
    <div style={{ background: 'white', borderRadius: '10px', margin: '20px', padding: '0 10px' }}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'end',
          alignItems: 'center',
          height: '80px',
        }}
      >
        <BaseButton onClick={handleShowAll}>Tổng thành viên ({filteredData.length})</BaseButton>
        <Select defaultValue="Phân loại" style={{ width: 190, marginLeft: '10px' }} onChange={handleShowType}>
          <Select.Option value="all">Tất cả</Select.Option>
          <Select.Option value="Học sinh / Sinh viên">Học sinh / Sinh viên</Select.Option>
          <Select.Option value="Giảng viên">Giảng viên</Select.Option>
        </Select>
        <div style={{ display: 'flex', marginLeft: 'auto' }}>
          <InputSearch
            style={{ width: '350px', marginRight: '10px' }}
            placeholder={t('Tìm kiếm theo ID, Họ tên')}
            onChange={handleSearch}
          />
        </div>
      </div>
      <BaseTable
        columns={columns}
        dataSource={filteredData}
        pagination={{ pageSize: 10 }}
        loading={tableData.loading}
        onChange={handleTableChange}
        scroll={{ x: 800 }}
        bordered
      />
      {modalChitiet}
      {modalUpdate}
    </div>
  );
};
