import { APIResponse, httpApi } from './http.api';
import { Pagination } from './table.api';

const ENDPOINT = 'book';
const enum API {
  get = '/',
}

export interface Book {
  Barcode: string;
  BookId: number;
  BookName: string;
  Authors: string[];
  Thumbnail: string;
  PublishYear: string;
  Publisher: string;
  Category: string;
  Location: string;
  Items: [
    {
      Barcode: string;
      Location: string;
      IsLocked: number;
    },
  ];
  Available: any;
  OnLoan: number;
  CreatedAt: string;
  PublisherName: string;
  IsLocked: number;
  CategoryId: number;
  Onloan: number;
}

export interface BookResponse {
  data: Book[];
  pagination: Pagination;
}

export interface ItemInput {
  Items: {
    Barcode: string[];
    LocationId: string;
    BookId: number;
  }[];
}
export interface BiblioInput {
  ISBN: string;
  BookName: string;
  AuthorName: string;
  PublisherName: string;
  PublishYear: number;
  Thumbnail: string;
  CategoryId: string;
  LocationId: string;
  Notes: string;
}

export const getBook = async (pagination: Pagination): Promise<BookResponse> => {
  const books: Book[] = await httpApi.get<APIResponse>(`${ENDPOINT}`).then((res) => res.data.data);
  return {
    data: books,
    pagination: { ...pagination, total: books.length },
  };
};

export const getBooks = async (pagination: Pagination): Promise<BookResponse> => {
  const books: Book[] = await httpApi.get<APIResponse>(`${ENDPOINT}/getBiblio`).then((res) => res.data.data);
  return {
    data: books,
    pagination: { ...pagination, total: books.length },
  };
};

export const getBookById = async (Barcode: string): Promise<Book> => {
  const book: Book = await httpApi
    .get<APIResponse>(`/book/getByBarcode`, {
      params: {
        Barcode: Barcode,
      },
    })
    .then((res) => res.data.data);
  return book;
};

export const getBiblioById = async (BookId: number): Promise<Book> => {
  const book: Book = await httpApi
    .get<APIResponse>(`/book/getBiblioById`, {
      params: {
        BookId: BookId,
      },
    })
    .then((res) => res.data.data);
  return book;
};

export const storeBookItem = async (data: ItemInput): Promise<any> => {
  try {
    const response = await httpApi.post<APIResponse>(`/book/store-items`, data);

    return response;
  } catch (error) {
    console.error('Error posting loan:', error);
    throw error;
  }
};

export const storeBookBiblio = async (data: any): Promise<any> => {
  try {
    const response = await httpApi.post<APIResponse>(`/book/store-biblio`, data);
    const postBook: Book = response.data.data;
    return postBook;
  } catch (error) {
    console.error('Error posting book:', error);
    throw error;
  }
};

export const putLock = async (Barcode: string) => {
  const putLock: Book = await httpApi
    .put<APIResponse>(`/book/lock-item`, {
      Barcode: Barcode,
    })
    .then((res) => res.data.data);
  return putLock;
};

export const putUnlock = async (Barcode: string) => {
  const putUnlock: Book = await httpApi
    .put<APIResponse>(`/book/unlock-item`, {
      Barcode: Barcode,
    })
    .then((res) => res.data.data);
  return putUnlock;
};

export const putLockBook = async (Barcode: string) => {
  const putLock: Book = await httpApi
    .put<APIResponse>(`/book/lock-item`, {
      Barcode: Barcode,
    })
    .then((res) => res.data.data);
  return putLock;
};

export const putUnlockBook = async (Barcode: string) => {
  const putUnlock: Book = await httpApi
    .put<APIResponse>(`/book/unlock-item`, {
      Barcode: Barcode,
    })
    .then((res) => res.data.data);
  return putUnlock;
};
