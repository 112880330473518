import React from 'react';
import { NotificationsDropdown } from '../components/notificationsDropdown/NotificationsDropdown';
import { ProfileDropdown } from '../components/profileDropdown/ProfileDropdown/ProfileDropdown';
import { SettingsDropdown } from '../components/settingsDropdown/SettingsDropdown';
import { HeaderFullscreen } from '../components/HeaderFullscreen/HeaderFullscreen';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { Link } from 'react-router-dom';

export const DesktopHeader: React.FC = () => {
  return (
    <BaseRow justify="space-between" align="middle">
      <BaseRow justify="space-between">
        <Link to="/" style={{ display: 'flex', alignItems: 'center' }}>
          <img src={'https://res.viendong.edu.vn/images/logoVD.png'} alt="viendong" width={60} height={60} />
          <div>
            <h2
              style={{
                fontWeight: 'bold',
                textShadow: '2px 2px 4px rgba(0, 0, 0, 0.2)',
                padding: '0 0 0 10px',
                margin: '0',
              }}
            >
              Digital Library Viễn Đông
            </h2>
            <h3
              style={{
                textShadow: '2px 2px 4px rgba(0, 0, 0, 0.2)',
                padding: '0 0 0 10px',
                margin: '0',
              }}
            >
              Thư viện số Viễn Đông
            </h3>
          </div>
        </Link>
      </BaseRow>

      <BaseRow align="middle" justify="end" gutter={[5, 5]}>
        {/* <BaseCol>
          <BaseRow gutter={[{ xxl: 5 }, { xxl: 5 }]}>
            <BaseCol>
                <HeaderFullscreen />
              </BaseCol>

            <BaseCol>
              <NotificationsDropdown />
            </BaseCol>

            <BaseCol>
              <SettingsDropdown />
            </BaseCol>
          </BaseRow>
        </BaseCol> */}

        <BaseCol>
          <ProfileDropdown />
        </BaseCol>
      </BaseRow>
    </BaseRow>
  );
};
