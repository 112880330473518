import React, { useEffect, useState, useCallback } from 'react';
import { BaseTable } from '@app/components/common/BaseTable/BaseTable';
import { ColumnsType } from 'antd/es/table';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { useTranslation } from 'react-i18next';
import { useMounted } from '@app/hooks/useMounted';
import { BaseSpace } from '@app/components/common/BaseSpace/BaseSpace';
import { EditOutlined, EyeOutlined, LockOutlined, UnlockOutlined } from '@ant-design/icons';
import { Button, Col, Form, Input, message, Modal, Row, Select, Tag, Tooltip } from 'antd';
import { getViTri, getViTriById, putLock, putUnlock, putUpdate, ViTri } from '@app/api/vitri.api';
import { InputSearch } from '@app/components/header/components/HeaderSearch/HeaderSearch.styles';
import { Loading } from '@app/components/common/Loading/Loading';
import { Label } from '@app/components/nft-dashboard/Balance/components/TopUpBalanceForm/TopUpBalanceForm.styles';
import { Category, getCategories } from '@app/api/category.api';

export const ViTriTable: React.FC = () => {
  const [tableData, setTableData] = useState<{ data: ViTri[]; loading: boolean }>({
    data: [],
    loading: false,
  });
  const [Categorys, setCategorys] = useState<{ data: Category[]; loading: boolean }>({
    data: [],
    loading: false,
  });
  const [isDetailModalVisible, setIsDetailModalVisible] = useState(false);
  const [isUpdateModalVisible, setIsUpdateModalVisible] = useState(false);
  const [selectedViTri, setselectedViTri] = useState<ViTri | null>(null);
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const { isMounted } = useMounted();
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedCategory, setSelectedCategory] = useState<string>('Default');

  const fetch = useCallback(() => {
    console.log('re-call');
    setTableData((tableData) => ({ ...tableData, loading: true }));
    getViTri({ current: 1, pageSize: 10 }).then((res) => {
      if (isMounted.current) {
        setTableData({ data: res.data, loading: false });
      }
    });
    getCategories({ current: 1, pageSize: 10 }).then((res) => {
      if (isMounted.current) {
        setCategorys({ data: res.data, loading: false });
      }
    });
  }, [isMounted]);

  useEffect(() => {
    fetch();
  }, [fetch]);

  const handleTableChange = () => {
    fetch();
  };

  const handleLock = async (LocationId: string, LocationName: string) => {
    try {
      await putLock(LocationId);
      message.success(`Vị trí ${LocationName} đã được khoá!`);
      setTableData((prevTableData) => ({
        ...prevTableData,
        data: prevTableData.data.map((vitri) => (vitri.LocationId === LocationId ? { ...vitri, IsLooked: 1 } : vitri)),
      }));
    } catch (error) {
      console.error('Error locking member:', error);
    }
  };

  const handleUnlock = async (LocationId: string, LocationName: string) => {
    try {
      await putUnlock(LocationId);
      message.success(`Vị trí ${LocationName} đã được mở khóa!`);
      setTableData((prevTableData) => ({
        ...prevTableData,
        data: prevTableData.data.map((vitri) => (vitri.LocationId === LocationId ? { ...vitri, IsLooked: 0 } : vitri)),
      }));
    } catch (error) {
      console.error('Error unlocking member:', error);
    }
  };

  const handleCancelUpdate = () => {
    setIsUpdateModalVisible(false);
    setselectedViTri(null);
  };

  const showModalUpdate = async (LocationId: string) => {
    setIsUpdateModalVisible(true);
    setTableData((prevState) => ({ ...prevState, loading: true }));

    try {
      const locationId = await getViTriById(LocationId);
      setselectedViTri(locationId);
      form.setFieldsValue(locationId);
    } catch (error) {
      console.error('Error fetching loan data:', error);
    } finally {
      setTableData((prevState) => ({ ...prevState, loading: false }));
    }
  };

  const handleUpdate = async () => {
    try {
      const updatedData = await form.validateFields();
      if (selectedViTri) {
        const transformedValues = {
          LocationId: updatedData.LocationId,
          LocationName: updatedData.LocationName,
          CategoryId: updatedData.CategoryId,
        };

        console.log('Received values from form: ', transformedValues);
        const response = await putUpdate(transformedValues);
        setIsUpdateModalVisible(false);
        form.resetFields();
        console.log('API response:', response);
        message.success('Vị trí đã được cập nhật thành công.');
        window.location.reload();
      }
    } catch (error) {
      message.error('Lỗi khi cập nhật thông tin vị trí');
      console.error('Error updating member:', error);
    }
  };

  const modalUpdate = (
    <Modal
      title={`Cập nhật thông tin: ${selectedViTri?.LocationName}`}
      open={isUpdateModalVisible}
      onCancel={handleCancelUpdate}
      footer={
        <div style={{ textAlign: 'center' }}>
          <Button type="primary" htmlType="submit" key="update" onClick={handleUpdate} size="middle">
            Xác Nhận
          </Button>
        </div>
      }
      width={800}
      style={{ top: 20 }}
    >
      {selectedViTri ? (
        <Form layout="vertical" form={form} initialValues={selectedViTri}>
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item label="ID Vị trí" name="LocationId">
                <Input disabled />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label="Tên vị trí" name="LocationName">
                <Input />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label="ID Danh mục" name="CategoryId">
                <Select
                  value={selectedCategory}
                  defaultValue={selectedCategory}
                  onChange={(e) => setSelectedCategory(e)}
                >
                  <Select.Option value="Default">Danh mục</Select.Option>
                  {Categorys.data.map((category) => (
                    <Select.Option key={category.CategoryCode} value={category.CategoryId}>
                      {category.CategoryName}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      ) : (
        <Loading />
      )}
    </Modal>
  );

  const columns: ColumnsType<ViTri> = [
    {
      title: t('common.idvitri'),
      dataIndex: 'LocationId',
      width: '10%',
      align: 'center',
    },
    {
      title: t('common.tenvitri'),
      dataIndex: 'LocationName',
      width: '10%',
      align: 'center',
    },
    {
      title: t('common.soluongsach'),
      dataIndex: 'BookCount',
      width: '10%',
      align: 'right',
    },
    {
      title: t('common.capnhatganday'),
      dataIndex: 'UpdatedAt',
      width: '10%',
      render: (text) => <span>{new Date(text).toLocaleDateString()}</span>,
      align: 'center',
    },
    {
      title: t('common.nguoicapnhat'),
      dataIndex: 'UpdatedBy',
    },
    {
      title: t('common.tinhtrang'),
      dataIndex: 'StatusName',
      width: '10%',
      align: 'center',
      render: (text) => <Tag color={text === 'Active' ? 'green' : 'red'}>{text}</Tag>,
    },
    {
      title: t('tables.actions'),
      dataIndex: 'actions',
      align: 'center',
      width: '10%',
      render: (_, record) => {
        return (
          <BaseSpace>
            <Tooltip title="Xem chi tiết">
              <BaseButton type="link" onClick={() => showModal(record.LocationId)}>
                <EyeOutlined />
              </BaseButton>
            </Tooltip>
            <Tooltip title="Chỉnh sửa thông tin">
              <BaseButton type="link" onClick={() => showModalUpdate(record.LocationId)} style={{ color: 'green' }}>
                <EditOutlined />
              </BaseButton>
            </Tooltip>
            {record.IsLooked === 0 ? (
              <Tooltip title="Khóa vị trí">
                <BaseButton type="link" danger onClick={() => handleLock(record.LocationId, record.LocationName)}>
                  <LockOutlined />
                </BaseButton>
              </Tooltip>
            ) : (
              <Tooltip title="Mở khóa vị trí">
                <BaseButton type="link" danger onClick={() => handleUnlock(record.LocationId, record.LocationName)}>
                  <UnlockOutlined />
                </BaseButton>
              </Tooltip>
            )}
          </BaseSpace>
        );
      },
    },
  ];

  const showModal = async (LocationId: string) => {
    setIsDetailModalVisible(true);
    setTableData((prevState) => ({ ...prevState, loading: true }));

    try {
      const vitri = await getViTriById(LocationId);
      setselectedViTri(vitri);
    } catch (error) {
      console.error('Error fetching location data:', error);
    } finally {
      setTableData((prevState) => ({ ...prevState, loading: false }));
    }
  };

  const handleCancel = () => {
    setIsDetailModalVisible(false);
    setselectedViTri(null);
  };

  const modalChitiet = (
    <Modal
      title={`${selectedViTri?.LocationId}`}
      open={isDetailModalVisible}
      onCancel={handleCancel}
      footer={null}
      style={{ top: 20 }}
      width={800}
    >
      {selectedViTri ? (
        <Form layout="horizontal">
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item label="ID Vị Trí:" name="LocationId">
                <Label style={{ color: 'black', fontWeight: 'bold' }}>{selectedViTri.LocationId}</Label>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label="Tên vị trí:" name="LocationName">
                <Label style={{ color: 'black', fontWeight: 'bold' }}>{selectedViTri.LocationName}</Label>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label="Tên danh mục:" name="CategoryName">
                <Label style={{ color: 'black', fontWeight: 'bold' }}>{selectedViTri.CategoryName}</Label>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label="Mã danh mục:" name="CategoryCode">
                <Label style={{ color: 'black', fontWeight: 'bold' }}>{selectedViTri.CategoryCode}</Label>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label="Số lượng sách:" name="BookCount">
                <Label style={{ color: 'black', fontWeight: 'bold' }}>{selectedViTri.BookCount}</Label>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      ) : (
        <Loading />
      )}
    </Modal>
  );

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
  };

  const filteredData = tableData.data.filter(
    (item) =>
      String(item.LocationId).toLowerCase().includes(searchTerm.toLowerCase()) ||
      String(item.CategoryName).toLowerCase().includes(searchTerm.toLowerCase()),
  );

  return (
    <div style={{ background: 'white', borderRadius: '10px', margin: '20px', padding: '0 10px' }}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'end',
          alignItems: 'center',
          height: '80px',
        }}
      >
        <InputSearch
          style={{ width: '350px', marginRight: '10px' }}
          placeholder={t('Tìm kiếm theo ID, Mã danh mục')}
          onChange={handleSearch}
        />
      </div>
      <BaseTable
        columns={columns}
        dataSource={filteredData}
        pagination={{ pageSize: 10 }}
        loading={tableData.loading}
        onChange={handleTableChange}
        scroll={{ x: 800 }}
        bordered
      />
      {modalChitiet}
      {modalUpdate}
    </div>
  );
};
