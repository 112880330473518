import React, { useEffect, useState, useCallback } from 'react';
import { BaseTable } from '@app/components/common/BaseTable/BaseTable';
import { ColumnsType } from 'antd/es/table';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { useTranslation } from 'react-i18next';
import { useMounted } from '@app/hooks/useMounted';
import { BaseSpace } from '@app/components/common/BaseSpace/BaseSpace';
import { EditOutlined, EyeOutlined, LockOutlined, UnlockOutlined, UploadOutlined } from '@ant-design/icons';
import {
  Button,
  Col,
  Form,
  Image,
  Input,
  message,
  Modal,
  Row,
  Select,
  TableColumnsType,
  Tooltip,
  Upload,
  UploadFile,
} from 'antd';
import { Book, getBiblioById, getBook } from '@app/api/book.api';
import { InputSearch } from '@app/components/header/components/HeaderSearch/HeaderSearch.styles';
import DetailModal from '@app/pages/CustomPages/Book/DetailModal';
import { Biblio, getBiblio, putLock, putUnlock, putUpdate } from '@app/api/themsach.api';
import { putLockBook, putUnlockBook } from '@app/api/book.api';
import { Loading } from '@app/components/common/Loading/Loading';
import TextArea from 'antd/lib/input/TextArea';
import { Category } from '@app/api/category.api';

export const ThongTinTable: React.FC = () => {
  const [tableBookData, setTableBookData] = useState<{ data: Book[]; loading: boolean }>({
    data: [],
    loading: false,
  });
  const [tableData, setTableData] = useState<{ data: Biblio[]; loading: boolean }>({
    data: [],
    loading: false,
  });
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [Categorys, setCategorys] = useState<{ data: Category[]; loading: boolean }>({
    data: [],
    loading: false,
  });
  const [form] = Form.useForm();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedBook, setSelectedBook] = useState<Book | null>(null);
  const [selectedBiblio, setselectedBiblio] = useState<Biblio | null>(null);
  const { t } = useTranslation();
  const { isMounted } = useMounted();
  const [searchTerm, setSearchTerm] = useState('');
  const [categoryOptions, setCategoryOptions] = useState<string[]>([]);
  const [isUpdateModalVisible, setIsUpdateModalVisible] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState<string>('Default');

  const [loading, setLoading] = useState(false);

  const fetch = useCallback(() => {
    setTableData((tableData) => ({ ...tableData, loading: true }));
    getBiblio({ current: 1, pageSize: 10 }).then((res) => {
      if (isMounted.current) {
        const books = res.data;
        const categories = Array.from(new Set(books.map((book) => book.Category)));
        setCategoryOptions(categories);
        setTableData({ data: books, loading: false });
      }
    });

    setTableBookData((tableBookData) => ({ ...tableBookData, loading: true }));
    getBook({ current: 1, pageSize: 10 }).then((res) => {
      if (isMounted.current) {
        const books = res.data;
        setTableBookData({ data: books, loading: false });
      }
    });
  }, [isMounted]);

  const handleUpdate = async () => {
    try {
      const updatedData = await form.validateFields();
      if (selectedBiblio) {
        const biblioData = new FormData();

        biblioData.append('BookId', updatedData.BookId);
        biblioData.append('ISBN', updatedData.ISBN);
        biblioData.append('BookName', updatedData.BookName);
        biblioData.append('AuthorName', updatedData.Authors);
        biblioData.append('PublisherName', updatedData.PublisherName);
        biblioData.append('PublishYear', updatedData.PublishYear);
        biblioData.append('CategoryId', updatedData.CategoryId);
        biblioData.append('Notes', updatedData.Notes);

        if (fileList.length > 0) {
          const thumbnailFile = fileList[0].originFileObj as File;
          biblioData.append('Thumbnail', thumbnailFile);
        } else {
          biblioData.append('Thumbnail', selectedBiblio?.Thumbnail || '');
        }

        const response = await putUpdate(biblioData);
        setIsUpdateModalVisible(false);
        form.resetFields();
        setFileList([]);
        console.log('API response:', response);
        message.success('Sách đã được cập nhật thành công.');
        window.location.reload();
      }
    } catch (error) {
      message.error('Lỗi khi cập nhật thông tin sách');
      console.error('Error updating biblio:', error);
    }
  };

  const handleCancelUpdate = () => {
    setIsUpdateModalVisible(false);
    setselectedBiblio(null);
  };

  const showModalUpdate = async (BookId: number) => {
    setIsUpdateModalVisible(true);
    setTableData((prevState) => ({ ...prevState, loading: true }));

    try {
      const bookid = await getBiblioById(BookId);
      setselectedBiblio(bookid);
      form.setFieldsValue(bookid);
    } catch (error) {
      console.error('Error fetching update biblio data:', error);
    } finally {
      setTableData((prevState) => ({ ...prevState, loading: false }));
    }
  };

  const handleFileChange = ({ fileList }: { fileList: UploadFile[] }) => {
    setFileList(fileList);
  };

  const handleLock = async (BookId: number, BookName: string) => {
    try {
      await putLock(BookId);
      message.success(`Sách ${BookName} đã được khoá!`);
      setTableData((prevTableData) => ({
        ...prevTableData,
        data: prevTableData.data.map((book) => (book.BookId === BookId ? { ...book, IsLocked: 1 } : book)),
      }));
    } catch (error) {
      console.error('Error locking biblio:', error);
    }
  };

  const handleUnlock = async (BookId: number, BookName: string) => {
    try {
      await putUnlock(BookId);
      message.success(`Sách ${BookName} đã được mở khóa!`);
      // setTableData((prevTableData) => ({
      //   ...prevTableData,
      //   data: prevTableData.data.map((book) => (book.BookId === BookId ? { ...book, IsLocked: 0 } : book)),
      // }));
    } catch (error) {
      console.error('Error unlocking biblio:', error);
    }
  };

  const handleLockBook = async (Barcode: string) => {
    try {
      await putLockBook(Barcode);
      message.success(`Barcode: ${Barcode} đã được khóa!`);

      setTableBookData((prevTableData) => ({
        ...prevTableData,
        data: prevTableData.data.map((book) => (book.Barcode === Barcode ? { ...book, IsLocked: 1 } : book)),
      }));
    } catch (error) {
      console.error('Error locking book:', error);
      message.error(`Không thể khóa sách với Barcode: ${Barcode}`);
    }
  };

  const handleUnlockBook = async (Barcode: string) => {
    try {
      await putUnlockBook(Barcode);
      message.success(`Barcode: ${Barcode} đã được mở khóa!`);
      setTableBookData((prevTableData) => ({
        ...prevTableData,
        data: prevTableData.data.map((book) => (book.Barcode === Barcode ? { ...book, IsLocked: 0 } : book)),
      }));
    } catch (error) {
      console.error('Error unlocking book:', error);
      message.error(`Không thể mở khóa sách với Barcode: ${Barcode}`);
    }
  };

  const modalColumns: TableColumnsType<any> = [
    {
      key: 'biblio-detail-#',
      title: '#',
      render: (text, record, index) => index + 1,
      align: 'center',
      width: '5%',
    },
    {
      key: 'biblio-detail-barcode',
      title: 'Barcode',
      dataIndex: 'Barcode',
      align: 'center',
    },
    {
      key: 'biblio-detail-location',
      title: 'Vị trí',
      dataIndex: 'Location',
      align: 'center',
      render: (record) => (record.length > 0 ? record : 'N/A'),
    },
    {
      title: t('tables.actions'),
      dataIndex: 'actions',
      width: '10%',
      align: 'center',
      render: (text, record) => {
        // return record.IsLooked === 0 ? 'khoa' : 'mo khoa';
        return (
          <BaseSpace>
            {record.IsLocked === 0 ? (
              <Tooltip title="Khóa sách">
                <BaseButton type="link" danger onClick={() => handleLockBook(record.Barcode)}>
                  <LockOutlined />
                </BaseButton>
              </Tooltip>
            ) : (
              <Tooltip title="Mở khóa sách">
                <BaseButton type="link" danger onClick={() => handleUnlockBook(record.Barcode)}>
                  <UnlockOutlined />
                </BaseButton>
              </Tooltip>
            )}
          </BaseSpace>
        );
      },
    },
  ];

  useEffect(() => {
    fetch();
  }, [fetch]);

  const handleTableChange = () => {
    fetch();
  };

  const showModal = async (bookId: number) => {
    setIsModalVisible(true);
    setTableData((prevState) => ({ ...prevState, loading: true }));

    try {
      const book = await getBiblioById(bookId);

      setSelectedBook(book);
    } catch (error) {
      console.error('Error fetching book data:', error);
    } finally {
      setTableData((prevState) => ({ ...prevState, loading: false }));
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setSelectedBook(null);
  };

  const modalUpdate = (
    <Modal
      title={`Cập nhật thông tin: ${selectedBiblio?.BookName}`}
      open={isUpdateModalVisible}
      onCancel={handleCancelUpdate}
      footer={
        <div style={{ textAlign: 'center' }}>
          <Button type="primary" htmlType="submit" key="update" onClick={handleUpdate} size="middle">
            Xác Nhận
          </Button>
        </div>
      }
      width={800}
      style={{ top: 20 }}
    >
      {selectedBiblio ? (
        <Form layout="vertical" form={form} initialValues={selectedBiblio}>
          <Row gutter={16}>
            <Col span={12} style={{ display: 'flex', justifyContent: 'center' }}>
              <Image src={selectedBiblio.Thumbnail} width={300} alt="Không có ảnh" />
            </Col>
            <Col span={12}>
              <Form.Item label="Ảnh bìa sách" name="Thumbnail">
                <Upload
                  listType="picture"
                  maxCount={1}
                  fileList={fileList}
                  beforeUpload={() => false}
                  onChange={handleFileChange}
                >
                  <Button icon={<UploadOutlined />}>Chọn tệp</Button>
                </Upload>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="ID Sách" name="BookId">
                <Input disabled />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="ISBN" name="ISBN">
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Tên sách"
                name="BookName"
                rules={[{ required: true, message: 'Vui lòng nhập tên sách!' }]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Tác giả" name="Authors">
                <Input value={selectedBiblio.Authors.join(', ')} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Nhà xuất bản"
                name="PublisherName"
                rules={[{ required: true, message: 'Vui lòng nhập nhà xuất bản!' }]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Năm xuất bản"
                name="PublishYear"
                rules={[{ required: true, message: 'Vui lòng nhập năm xuất bản!' }]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="ID Danh mục"
                name="CategoryId"
                initialValue={selectedBiblio.CategoryId}
                rules={[{ required: true, message: 'Vui lòng nhập ID Danh mục!' }]}
                hidden={true}
              >
                <Select
                  value={selectedCategory}
                  defaultValue={selectedCategory}
                  onChange={(e) => setSelectedCategory(e)}
                >
                  <Select.Option value="Default">Danh mục</Select.Option>
                  {Categorys.data.map((category) => (
                    <Select.Option key={category.CategoryCode} value={category.CategoryId}>
                      {category.CategoryName}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label="Ghi chú" name="Notes">
                <TextArea autoSize={{ minRows: 3 }} />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      ) : (
        <Loading />
      )}
    </Modal>
  );

  const columns: ColumnsType<Biblio> = [
    {
      key: 'biblio-id',
      title: t('common.IDsach'),
      dataIndex: 'BookId',
      width: '10%',
      align: 'right',
    },
    {
      key: 'book-name',
      title: t('common.tensach'),
      dataIndex: 'BookName',
    },
    {
      key: 'biblio-authors',
      title: t('common.tacgia'),
      dataIndex: 'Authors',
      render: (value) => value.join(' - '),
    },
    {
      key: 'biblio-publish-year',
      title: t('common.namxb'),
      dataIndex: 'PublishYear',
      width: '10%',
      align: 'right',
    },
    // {
    //   key: 'biblio-category',
    //   title: t('Danh mục'),
    //   dataIndex: 'Category',
    //   width: '10%',
    // },
    {
      key: 'biblio-avaiable',
      title: 'Sách có sẵn',
      dataIndex: 'Available',
      width: '10%',
      align: 'right',
    },
    {
      key: 'biblio-actions',
      title: t('tables.actions'),
      dataIndex: 'actions-modal',
      width: '10%',
      align: 'center',
      render: (text, record) => {
        return (
          <BaseSpace>
            <Tooltip title="Xem chi tiết">
              <BaseButton type="link" onClick={() => showModal(record.BookId)}>
                <EyeOutlined />
              </BaseButton>
            </Tooltip>

            <Tooltip title="Chỉnh sửa thông tin">
              <BaseButton type="link" onClick={() => showModalUpdate(record.BookId)} style={{ color: 'green' }}>
                <EditOutlined />
              </BaseButton>
            </Tooltip>

            {record.IsLocked == 0 ? (
              <Tooltip title="Khóa sách">
                <BaseButton type="link" danger onClick={() => handleLock(record.BookId, record.BookName)}>
                  <LockOutlined />
                </BaseButton>
              </Tooltip>
            ) : (
              <Tooltip title="Mở khóa sách">
                <BaseButton type="link" danger onClick={() => handleUnlock(record.BookId, record.BookName)}>
                  <UnlockOutlined />
                </BaseButton>
              </Tooltip>
            )}
          </BaseSpace>
        );
      },
    },
  ];

  const filteredData = tableData.data
    .filter((item) => selectedCategory === 'Default' || item.Category === selectedCategory)
    .filter(
      (item) =>
        String(item.BookId).toLowerCase().includes(searchTerm.toLowerCase()) ||
        String(item.Category).toLowerCase().includes(searchTerm.toLowerCase()) ||
        String(item.BookName).toLowerCase().includes(searchTerm.toLowerCase()),
    );

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
  };

  const handleCategoryChange = (value: string) => {
    setSelectedCategory(value);
  };

  return (
    <div style={{ background: 'white', borderRadius: '10px', margin: '20px', padding: '0 10px' }}>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          height: '80px',
        }}
      >
        <div
          style={{
            marginRight: 'auto',
          }}
        >
          <Select
            defaultValue="Danh mục"
            style={{ width: 210, borderRadius: '8px' }}
            bordered={true}
            dropdownStyle={{ borderRadius: '8px' }}
            onChange={handleCategoryChange}
          >
            <Select.Option value="Default">Danh mục</Select.Option>
            {categoryOptions.map((category) => (
              <Select.Option key={category} value={category}>
                {category}
              </Select.Option>
            ))}
          </Select>
        </div>
        <div style={{ display: 'flex', marginLeft: 'auto' }}>
          <InputSearch
            style={{ width: '400px', marginRight: '10px' }}
            placeholder={t('Tìm kiếm theo ID, Tên sách, Danh mục')}
            onChange={handleSearch}
          />
        </div>
      </div>
      <BaseTable
        columns={columns}
        dataSource={filteredData}
        pagination={{ pageSize: 10 }}
        loading={tableData.loading}
        onChange={handleTableChange}
        scroll={{ x: 800 }}
        bordered
      />
      <DetailModal
        selectedBook={selectedBook}
        isModalVisible={isModalVisible}
        onHandleCancel={handleCancel}
        columns={modalColumns}
      />
      {modalUpdate}
    </div>
  );
};
