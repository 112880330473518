import React from 'react';
import { ProfileOverlay } from '../ProfileOverlay/ProfileOverlay';
import { useAppSelector } from '@app/hooks/reduxHooks';
import { useResponsive } from '@app/hooks/useResponsive';
import * as S from './ProfileDropdown.styles';
import { BasePopover } from '@app/components/common/BasePopover/BasePopover';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { BaseAvatar } from '@app/components/common/BaseAvatar/BaseAvatar';

export const ProfileDropdown: React.FC = () => {
  const { isTablet } = useResponsive();

  const user = useAppSelector((state) => state.user.user);

  return user ? (
    <BasePopover content={<ProfileOverlay />} trigger="click">
      <S.ProfileDropdownHeader as={BaseRow} gutter={[10, 10]} align="middle">
        <BaseCol>
          <BaseAvatar
            src={
              user.UserImage ||
              'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRMa0vsQlVnTOg7LAJkKLINzs5TlUG3zkaNZA&s'
            }
            alt="User"
            shape="circle"
            size={50}
          />
        </BaseCol>
        {isTablet && (
          <BaseCol>
            <span>{`${user.Realname}`}</span>
            <p
              style={{
                fontSize: 14,
                color: 'blue',
              }}
            >
              Ấn vào đây để thoát.
            </p>
          </BaseCol>
        )}
      </S.ProfileDropdownHeader>
    </BasePopover>
  ) : null;
};
